import {
    FontWeights,
    GroupHeader,
    IGroupHeaderProps,
    Icon,
    Link,
    Spinner,
    SpinnerSize,
    makeStyles as legacyMakeStyles,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedNumber, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDynamicMakeStyles } from '../../hooks/styling';
import { getTokensFromCustomizationGroupDataPlaneUri } from '../../ids/customization-group';
import { CustomizationTask, CustomizationTaskStatus } from '../../models/customization';
import { getCustomizationTaskLogs } from '../../redux/selector/customization-selectors';
import { useHorizontalStackStyles } from '../../themes/styles/flexbox-styles';
import { SerializableMap } from '../../types/serializable-map';
import { get } from '../../utilities/serializable-map';
import { getSemanticColor } from '../../utilities/styles';
import { getMillisecondsBetween } from '../../utilities/time';
import { FluentIconNames } from '../common/fluent-icon-names';

interface CustomizationTaskDetailsContainerProps {
    groupHeaderProps?: IGroupHeaderProps;
    task?: CustomizationTask;
    customizationGroupUri: string;
}

interface CustomizationTaskDetailsProps extends CustomizationTaskDetailsContainerProps {
    taskLogs: SerializableMap<string>;
}

interface TaskIconProps {
    status?: CustomizationTaskStatus;
}

const messages = defineMessages({
    customizationTaskLogFileName: {
        id: 'CustomizationTaskDetails_CustomizationTaskLogFile_Name',
        defaultMessage: '{devBoxName}_{customizationGroupName}_{taskName}',
        description:
            'Name for the downloaded customization task log file. {devBoxName}, {customizationGroupName} and {taskName} should not be localized.',
    },
});

/**
 * Styles
 */

const useTimeStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'customizationTaskTimeText'),
        paddingRight: '2px',
    },
}));

const groupHeaderStylesFactory = (isCollapsed: boolean, hasParameters: boolean) =>
    legacyMakeStyles((theme) => ({
        expand: {
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
            display: hasParameters ? '' : 'none',
        },
        expandIsCollapsed: {
            color: isCollapsed ? getSemanticColor(theme, 'expandIcon') : getSemanticColor(theme, 'activeExpandIcon'),
        },
        groupHeaderContainer: {
            backgroundColor: isCollapsed
                ? getSemanticColor(theme, 'collapsedCustomizationTaskBackground')
                : getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
            margin: isCollapsed ? '6px 0' : '6px 0 0 0',
            fontWeight: isCollapsed ? FontWeights.regular : FontWeights.bold,
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
            padding: hasParameters ? '0' : '0 0 0 35px',
        },
    }));

const useCustomizationTaskNameStyles = makeStyles({
    collapsed: {
        fontWeight: FontWeights.regular,
    },
    expanded: {
        fontWeight: FontWeights.bold,
    },
});

const useSuccessIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'successMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useErrorIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'errorMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useQueuedIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'queuedIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useSkippedIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'skippedIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useDownloadButtonStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'downloadButtonText'),
        selectors: {
            ':hover': {
                textDecoration: 'none',
            },
        },
    },
}));

const useDownloadIconStyles = makeStyles({
    root: {
        padding: '0 8px 0 0',
    },
});

const useDownloadContainerStyles = makeStyles({
    root: {
        marginLeft: 'auto',
    },
});

const useCustomizationTaskStyles = makeStyles({
    root: {
        gap: '8px',
        alignItems: 'center',
        width: '100%',
    },
});

/**
 * End Styles
 */

const TaskIcon: React.FC<TaskIconProps> = React.memo((props: TaskIconProps) => {
    const { status } = props;

    const successIconStyles = useSuccessIconStyles();
    const errorIconStyles = useErrorIconStyles();
    const queuedIconStyles = useQueuedIconStyles();
    const skippedIconStyles = useSkippedIconStyles();

    switch (status) {
        case CustomizationTaskStatus.Running:
            return <Spinner ariaLabel={'spinner'} size={SpinnerSize.small} />;
        case CustomizationTaskStatus.Succeeded:
            return <Icon iconName={FluentIconNames.Accept} styles={successIconStyles} />;
        case CustomizationTaskStatus.FailedValidation:
        case CustomizationTaskStatus.TimedOut:
        case CustomizationTaskStatus.Failed:
            return <Icon iconName={FluentIconNames.Cancel} styles={errorIconStyles} />;
        case CustomizationTaskStatus.Skipped:
            return <Icon iconName={FluentIconNames.Blocked} styles={skippedIconStyles} />;
        case CustomizationTaskStatus.NotStarted:
        default:
            return <Icon iconName={FluentIconNames.CircleRing} styles={queuedIconStyles} />;
    }
});

export const CustomizationTaskDetails: React.FC<CustomizationTaskDetailsProps> = React.memo(
    (props: CustomizationTaskDetailsProps) => {
        const { groupHeaderProps, task, customizationGroupUri, taskLogs } = props;
        const { group } = groupHeaderProps ?? {};
        const { status, endTime, startTime, logUri, parameters } = task ?? {};
        const { devBoxName, customizationGroupName } =
            getTokensFromCustomizationGroupDataPlaneUri(customizationGroupUri);

        const isCollapsed = group?.isCollapsed ?? true;

        // Intl hooks
        const { formatMessage } = useIntl();

        const hasParameters = React.useMemo(() => {
            if (parameters) {
                return Object.keys(parameters).length > 0;
            }

            return false;
        }, [parameters]);

        // Style hooks
        const timeStyles = useTimeStyles();
        const useGroupHeaderStyles = useDynamicMakeStyles(
            groupHeaderStylesFactory,
            group?.isCollapsed ?? true,
            hasParameters
        );
        const groupHeaderStyles = useGroupHeaderStyles();
        const taskNameStyles = useCustomizationTaskNameStyles();
        const downloadButtonStyles = useDownloadButtonStyles();
        const downloadIconStyles = useDownloadIconStyles();
        const downloadContainerStyles = useDownloadContainerStyles();
        const horizontalStackStyles = useHorizontalStackStyles();
        const customizationTaskStyles = useCustomizationTaskStyles();

        const timeTaken = React.useMemo(
            () => (!!endTime && !!startTime ? getMillisecondsBetween(startTime, endTime) / 1000 : 0),
            [startTime, endTime]
        );

        const taskLogUrl = React.useMemo(() => get(taskLogs, logUri), [taskLogs, logUri]);

        const getTaskLogFileName = React.useMemo(
            () =>
                formatMessage(messages.customizationTaskLogFileName, {
                    devBoxName,
                    customizationGroupName,
                    taskName: task?.name,
                }),
            [task, formatMessage]
        );

        const onRenderName = React.useCallback(
            (props?: IGroupHeaderProps): JSX.Element => {
                return (
                    <div className={mergeClasses(horizontalStackStyles.root, customizationTaskStyles.root)}>
                        <div className={horizontalStackStyles.item}>
                            <TaskIcon status={status} />
                        </div>
                        <div
                            className={mergeClasses(
                                horizontalStackStyles.item,
                                isCollapsed ? taskNameStyles.collapsed : taskNameStyles.expanded
                            )}
                        >
                            {props?.group?.name}
                        </div>
                        {startTime && endTime && (
                            <div className={mergeClasses(horizontalStackStyles.item, timeStyles.root)}>
                                <FormattedNumber value={timeTaken} style="unit" unit="second" unitDisplay="narrow" />
                            </div>
                        )}
                        {startTime && endTime && (
                            <div className={mergeClasses(horizontalStackStyles.item, downloadContainerStyles.root)}>
                                <Link
                                    href={taskLogUrl}
                                    download={getTaskLogFileName}
                                    target="_blank"
                                    rel="noreferrer"
                                    styles={downloadButtonStyles}
                                >
                                    <Icon iconName={FluentIconNames.Download} styles={downloadIconStyles} />
                                </Link>
                            </div>
                        )}
                    </div>
                );
            },
            [status, timeStyles, startTime, endTime, timeTaken, taskNameStyles, isCollapsed]
        );

        return <GroupHeader onRenderTitle={onRenderName} styles={groupHeaderStyles} {...groupHeaderProps} />;
    }
);

export const CustomizationTaskDetailsContainer: React.FC<CustomizationTaskDetailsContainerProps> = (
    props: CustomizationTaskDetailsContainerProps
) => {
    const { task, customizationGroupUri, groupHeaderProps } = props;

    const taskLogs = useSelector(getCustomizationTaskLogs);

    return (
        <CustomizationTaskDetails
            taskLogs={taskLogs}
            task={task}
            customizationGroupUri={customizationGroupUri}
            groupHeaderProps={groupHeaderProps}
        />
    );
};

export default CustomizationTaskDetailsContainer;

import { DefaultButton, DirectionalHint, IContextualMenuProps } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PlatformArchitectureFamily } from 'src/constants/browser';
import { ClientDownloadLink } from '../../../constants/avd';
import { getSystemArchitecture, openInSameWindow } from '../../../utilities/browser';

interface DownloadMenuItem {
    ariaLabel: string;
    key: string;
    onClick: () => void;
    text: string;
}

const messages = defineMessages({
    primaryButton: {
        id: 'DownloadWindowsClientButton_PrimaryButton_Text',
        defaultMessage: 'Windows 64 bit',
        description: 'Text for primary action of "Download Windows client" button',
    },
    primaryButtonAriaLabel: {
        id: 'DownloadWindowsClientButton_PrimaryButton_AriaLabel',
        defaultMessage: 'Download Windows 64 bit',
        description: 'Aria label for primary action of "Download Windows client" button',
    },
    windowsARM64Option: {
        id: 'DownloadWindowsClientButton_WindowsARM64Option_Text',
        defaultMessage: 'Windows ARM 64',
        description: 'Text for "Windows ARM 64" menu item of "Download Windows client" button',
    },
    windowsARM64OptionAriaLabel: {
        id: 'DownloadWindowsClientButton_WindowsARM64Option_AriaLabel',
        defaultMessage: 'Download Windows ARM 64',
        description: 'Aria label for "Windows ARM 64" menu item of "Download Windows client" button',
    },
    windowsX64Option: {
        id: 'DownloadWindowsClientButton_WindowsX64Option_Text',
        defaultMessage: 'Windows 64 bit',
        description: 'Text for "Windows 64 bit" menu item of "Download Windows client" button',
    },
    windowsX64OptionAriaLabel: {
        id: 'DownloadWindowsClientButton_WindowsX64Option_AriaLabel',
        defaultMessage: 'Download Windows 64 bit',
        description: 'Aria label for "Windows 64 bit" menu item of "Download Windows client" button',
    },
    windowsX86Option: {
        id: 'DownloadWindowsClientButton_WindowsX86Option_Text',
        defaultMessage: 'Windows 32 bit',
        description: 'Text for "Windows 32 bit" menu item of "Download Windows client" button',
    },
    windowsX86OptionAriaLabel: {
        id: 'DownloadWindowsClientButton_WindowsX86Option_AriaLabel',
        defaultMessage: 'Download Windows 32 bit',
        description: 'Aria label for "Windows 32 bit" menu item of "Download Windows client" button',
    },
});

const onWindowsARM64OptionCicked = (): void => openInSameWindow(ClientDownloadLink.WindowsARM64);
const onWindowsX64OptionCicked = (): void => openInSameWindow(ClientDownloadLink.WindowsX64);
const onWindowsX86OptionCicked = (): void => openInSameWindow(ClientDownloadLink.WindowsX86);
const systemArchitecture = getSystemArchitecture();

export const DownloadWindowsClientButton: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();
    const orderedWindowsDownloadItems: DownloadMenuItem[] = React.useMemo(() => {
        // Define menu items
        const windowsX64Item: DownloadMenuItem = {
            ariaLabel: formatMessage(messages.windowsX64OptionAriaLabel),
            key: 'windows-x64',
            onClick: onWindowsX64OptionCicked,
            text: formatMessage(messages.windowsX64Option),
        };

        const windowsX86Item: DownloadMenuItem = {
            ariaLabel: formatMessage(messages.windowsX86OptionAriaLabel),
            key: 'windows-x86',
            onClick: onWindowsX86OptionCicked,
            text: formatMessage(messages.windowsX86Option),
        };

        const windowsARM64Item: DownloadMenuItem = {
            ariaLabel: formatMessage(messages.windowsARM64OptionAriaLabel),
            key: 'windows-arm64',
            onClick: onWindowsARM64OptionCicked,
            text: formatMessage(messages.windowsARM64Option),
        };

        switch (systemArchitecture) {
            case PlatformArchitectureFamily.Arm64:
                return [windowsARM64Item, windowsX64Item, windowsX86Item]; // ARM64 at the top
            case PlatformArchitectureFamily.x86:
                return [windowsX86Item, windowsX64Item, windowsARM64Item]; // x86 at the top
            default:
                return [windowsX64Item, windowsX86Item, windowsARM64Item]; // Default order if x64 or unknown
        }
    }, [formatMessage]);

    // Menu properties
    const menuProps = React.useMemo<IContextualMenuProps>(
        () => ({
            directionalHint: DirectionalHint.bottomRightEdge,
            items: orderedWindowsDownloadItems,
        }),
        [orderedWindowsDownloadItems]
    );

    return (
        <DefaultButton
            text={formatMessage(messages.primaryButton)}
            primary
            split
            splitButtonAriaLabel={formatMessage(messages.primaryButtonAriaLabel)}
            aria-roledescription="split button"
            menuProps={menuProps}
            onClick={onWindowsX64OptionCicked}
        />
    );
};

export default DownloadWindowsClientButton;

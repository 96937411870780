import { FontSizes, FontWeights, PrimaryButton } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import notSignedInImage from '../content/images/Home.svg';
import { useActionCreator } from '../hooks/action-creator';
import commonMessages from '../language/messages';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';
import { signIn } from '../redux/actions/identity/identity-action-creators';
import { useStackStyles } from '../themes/styles/flexbox-styles';
import { ReturnVoid } from '../types/return-void';
import { isDevPortalEnvironment } from '../utilities/app';

interface UnauthenticatedPageComponentProps {
    onSignInSubmitted: ReturnVoid<typeof signIn>;
}

const messages = defineMessages({
    signedOutImageAlt: {
        id: 'UnauthenticatedPage_SignedOutImage_Alt',
        defaultMessage: 'Not signed in',
        description: 'Alt text for "not signed in" image',
    },
    signInButtonLabel: {
        id: 'UnauthenticatedPage_SignInButton_Label',
        defaultMessage: 'Sign in',
        description: 'Aria label for sign in button',
    },
    signInButtonText: {
        id: 'UnauthenticatedPage_SignInButton_Text',
        defaultMessage: 'Sign in',
        description: 'Text for sign in button',
    },
});

/**
 * Styles
 */

const useSignInButtonContainerStyles = makeStyles({
    // Note: using paddingTop to space StackItems as Stack will override margins
    root: {
        lineHeight: '20px',
        paddingTop: '20px',
    },
});

const useSignInMessageContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        paddingTop: '6px',
    },
});

const useWelcomeMessageContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        paddingTop: '6px',
    },
});

const useContainerStyles = makeStyles({
    root: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

/**
 * End Styles
 */

const welcomeMessage = isDevPortalEnvironment
    ? commonMessages.welcomeMessageDevPortalText
    : commonMessages.welcomeMessageText;

export const UnauthenticatedPageComponent: React.FC<UnauthenticatedPageComponentProps> = (
    props: UnauthenticatedPageComponentProps
) => {
    const { onSignInSubmitted } = props;

    // Callback hooks
    // Note: this useCallback prevents onClick event args from being sent to redux, which will cause errors
    const onSignInButtonClicked = React.useCallback(() => onSignInSubmitted({}), [onSignInSubmitted]);

    // Style hooks
    const signInButtonContainerStyles = useSignInButtonContainerStyles();
    const signInMessageContainerStyles = useSignInMessageContainerStyles();
    const welcomeMessageContainerStyles = useWelcomeMessageContainerStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <>
            <CenteredPortalLayout id="unauthenticated" showFooter>
                <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                    <div className={stackStyles.item}>
                        <img alt={formatMessage(messages.signedOutImageAlt)} src={notSignedInImage} />
                    </div>
                    <div className={mergeClasses(stackStyles.item, welcomeMessageContainerStyles.root)}>
                        <FormattedMessage {...welcomeMessage} />
                    </div>

                    <div className={mergeClasses(stackStyles.item, signInMessageContainerStyles.root)}>
                        <FormattedMessage
                            id="UnauthenticatedPage_SignInMessage_Text"
                            defaultMessage="Sign in to access or manage your dev boxes"
                            description="Message requesting that the user sign in to make use of the portal"
                        />
                    </div>

                    <div className={mergeClasses(stackStyles.item, signInButtonContainerStyles.root)}>
                        <PrimaryButton
                            ariaLabel={formatMessage(messages.signInButtonLabel)}
                            id="UnauthenticatedPage_SignInButton"
                            onClick={onSignInButtonClicked}
                            text={formatMessage(messages.signInButtonText)}
                        />
                    </div>
                </div>
            </CenteredPortalLayout>
        </>
    );
};

export const UnauthenticatedPageContainer: React.FC = () => {
    // Action hooks
    const onSignInSubmitted = useActionCreator(signIn);

    return <UnauthenticatedPageComponent onSignInSubmitted={onSignInSubmitted} />;
};

export default UnauthenticatedPageContainer;

import { makeStyles as legacyMakeStyles } from '@fluentui/react';
import { mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { AppSemanticColor } from '../../../../../../themes/app-semantic-colors';
import { useStackStyles } from '../../../../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../../../../utilities/styles';
import { RegionViewModel } from '../../../../../add-dev-box-panel/models';
import { DropdownDividerOption } from '../../divider';
import ValueDropdown, { ValueDropdownOption, ValueDropdownWrapperProps } from '../../value-dropdown';
import { getOptionKeyForIndexedModel } from '../selectors';
import RegionDropdownItem from './region-dropdown-item';

type DropdownProps = ValueDropdownWrapperProps<RegionViewModel>;

/**
 * Styles
 */

const useLinkStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, AppSemanticColor.seeAllLink),
    },
}));

/**
 * END Styles
 */

export const RegionDropdown: React.FC<DropdownProps> = (props) => {
    const { options } = props;

    // Style hooks
    const linkStyles = useLinkStyles();
    const stackStyles = useStackStyles();

    const onRenderOption = React.useCallback(
        (value: RegionViewModel): JSX.Element => {
            if (value.id === 'show-all') {
                return (
                    <div className={mergeClasses(linkStyles.root, stackStyles.item)}>
                        <FormattedMessage
                            id="AddDevBoxFormRegionControls_ShowAllRegionsLink_Text"
                            defaultMessage="Show all"
                            description="Text for the show all regions link for the add dev box region form field"
                        />
                    </div>
                );
            }

            return <RegionDropdownItem region={value} />;
        },
        [linkStyles, stackStyles]
    );

    const optionsWithDivider: ValueDropdownOption<RegionViewModel>[] = React.useMemo(() => {
        // If there is only one option, don't insert divider.
        if (options.length > 1 && options[options.length - 1]) {
            const optionsWithDivider: ValueDropdownOption<RegionViewModel>[] = options.slice(0, options.length - 1);
            optionsWithDivider.push(DropdownDividerOption('region-divider'), options[options.length - 1]);

            return optionsWithDivider;
        }
        return options;
    }, [options]);

    return (
        <ValueDropdown<RegionViewModel>
            {...props}
            onRenderOption={onRenderOption}
            options={optionsWithDivider}
            getOptionKey={getOptionKeyForIndexedModel}
        />
    );
};

export default RegionDropdown;

import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { get } from '../../../utilities/serializable-map';
import ImageDropdown from '../../common/form/dropdown/data-controls/image-dropdown';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import Label from '../../common/form/label';
import ReadOnlyControl from '../../common/form/read-only-control';
import { ImageViewModel, ImageViewModelMap } from '../models';
import SelectDevBoxImageDialog from '../select-dev-box-image-dialog/select-dev-box-image-dialog';

interface AddDevBoxFormImageControlProps {
    disabled: boolean;
    imagesToPools: ImageViewModelMap;
    selectedProjectId: string | undefined;
    selectedImage: ImageViewModel | undefined;
    errorMessage: string;
    onChange: (event: ImageViewModel | undefined) => void;
}

const messages = defineMessages({
    addDevBoxFormImageFieldDropdownText: {
        id: 'AddDevBoxFormImageControl_Dropdown_Text',
        defaultMessage: 'Image',
        description: 'Text for the image dropdown in the add dev box panel label',
    },
    addDevBoxFormImageFieldDropdownToolTipText: {
        id: 'AddDevBoxFormImageControl_Dropdown_ToolTipText',
        defaultMessage: 'Choose an image that contains the tools and code needed for your development tasks.',
        description: 'Tooltip text for the image dropdown in the add dev box panel label',
    },
    addDevBoxFormImageFieldDropdownAriaLabel: {
        id: 'AddDevBoxFormImageControl_Dropdown_AriaLabel',
        defaultMessage: 'Image for your dev box',
        description: 'Aria label for the image dropdown in the add dev box panel',
    },
    addDevBoxFormImageFieldDropdownPlaceholder: {
        id: 'AddDevBoxFormImageControl_Dropdown_Placeholder',
        defaultMessage: 'Select image',
        description: 'Placeholder text for the image dropdown in the add dev box panel',
    },
});

/**
 * Styles
 */

const useSelectionContainerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

const useImageReadOnlyOptionContainerStyles = makeStyles({
    root: {
        gap: '5px',
    },
});

/**
 * END Styles
 */

export const AddDevBoxFormImageControl: React.FC<AddDevBoxFormImageControlProps> = (
    props: AddDevBoxFormImageControlProps
) => {
    const { disabled, selectedImage, selectedProjectId, imagesToPools, errorMessage, onChange } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const imageReadOnlyOptionContainerStyles = useImageReadOnlyOptionContainerStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const selectionContainerStyles = useSelectionContainerStyles();

    // State hooks
    const [showSelectDevBoxImageDialog, setShowSelectDevBoxImageDialog] = React.useState(false);

    // Memo hooks
    const imagesToProjects: ImageViewModel[][] = React.useMemo(
        () => (!!selectedProjectId ? get(imagesToPools, selectedProjectId) ?? [] : []),
        [imagesToPools, selectedProjectId]
    );

    // We just use the info from the first image in each array because it is all the same information besides the region which is fine since region doesn't matter here
    const images: ImageViewModel[] = React.useMemo(
        () => imagesToProjects.map((image) => ({ ...image[0] })),
        [imagesToProjects]
    );

    const hasMultipleImages = images.length > 1;
    const hasNoImages = images.length < 1;

    const imagesWithSeeAll: ImageViewModel[] = React.useMemo(() => {
        const imagesCopy = [...images];
        if (hasMultipleImages) {
            imagesCopy.push({
                id: 'see-all-images',
                imageName: 'all-images',
                name: 'See all images',
                region: 'Global',
            });
        }

        return imagesCopy;
    }, [images, hasMultipleImages]);

    const selectDevBoxImageModalOnDismiss = React.useCallback(() => setShowSelectDevBoxImageDialog(false), []);
    const onSeeAllImagesButtonClick = React.useCallback(() => setShowSelectDevBoxImageDialog(true), []);

    // pre-select the first image if we only have one
    React.useEffect(() => {
        if (images.length === 1) {
            onChange(images[0]);
        }
    }, [images, onChange]);

    const onRenderLabel = React.useCallback((): JSX.Element => {
        return (
            <Label tooltipValue={formatMessage(messages.addDevBoxFormImageFieldDropdownToolTipText)}>
                <FormattedMessage
                    id="AddDevBoxFormImageControl_Dropdown_Text"
                    defaultMessage="Image"
                    description="Text for the image dropdown in the add dev box panel label"
                />
            </Label>
        );
    }, [formatMessage]);

    const onChangeImage = React.useCallback(
        (value: ImageViewModel | undefined) => {
            if (value?.id === 'see-all-images') {
                // Setting onChange to be first value in images array if 'see all' is chosen first
                if (selectedImage === undefined) {
                    onChange(images[0]);
                }
                onSeeAllImagesButtonClick();
                return;
            }

            onChange(value);
        },
        [onChange, onSeeAllImagesButtonClick, images, selectedImage]
    );

    if (hasMultipleImages) {
        return (
            <div className={mergeClasses(stackStyles.root, selectionContainerStyles.root)}>
                <div className={stackStyles.item}>
                    <ImageDropdown
                        value={selectedImage}
                        label={formatMessage(messages.addDevBoxFormImageFieldDropdownText)}
                        ariaLabel={formatMessage(messages.addDevBoxFormImageFieldDropdownAriaLabel)}
                        placeholder={formatMessage(messages.addDevBoxFormImageFieldDropdownPlaceholder)}
                        options={imagesWithSeeAll}
                        onChange={onChangeImage}
                        onRenderLabel={onRenderLabel}
                        disabled={disabled}
                        errorMessage={errorMessage}
                        autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                        required
                    />
                </div>
                <div className={stackStyles.item}>
                    <SelectDevBoxImageDialog
                        images={images}
                        onDismiss={selectDevBoxImageModalOnDismiss}
                        showDialog={showSelectDevBoxImageDialog}
                        onChange={onChange}
                        selectedImage={selectedImage}
                    />
                </div>
            </div>
        );
    }

    if (!selectedImage || hasNoImages) {
        return <></>;
    }

    return (
        <div className={mergeClasses(stackStyles.root, imageReadOnlyOptionContainerStyles.root)}>
            <div className={stackStyles.item}>
                <ReadOnlyControl
                    label={formatMessage(messages.addDevBoxFormImageFieldDropdownText)}
                    value={selectedImage.name}
                    tooltipText={formatMessage(messages.addDevBoxFormImageFieldDropdownToolTipText)}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default AddDevBoxFormImageControl;

import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LatencyIcon } from '../../../../../../icons/latency-icon';
import { useHorizontalStackStyles } from '../../../../../../themes/styles/flexbox-styles';
import { RegionViewModel } from '../../../../../add-dev-box-panel/models';

interface RegionDropdownItemProps {
    region: RegionViewModel;
}

/**
 * Styles
 */

const useDropdownOptionContainerStyles = makeStyles({
    root: {
        gap: '6px',
    },
});

/**
 * End Styles
 */

export const RegionDropdownItem: React.FC<RegionDropdownItemProps> = (props: RegionDropdownItemProps) => {
    const { region } = props;
    const { latencyBand, name } = region;

    // Style hooks
    const dropdownOptionContainerStyles = useDropdownOptionContainerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    const regionValues = React.useMemo(
        () => ({ region: name, latencyBand: latencyBand.toLowerCase() }),
        [name, latencyBand]
    );

    return (
        <div className={mergeClasses(horizontalStackStyles.root, dropdownOptionContainerStyles.root)}>
            <div className={horizontalStackStyles.item}>
                <LatencyIcon latencyBand={latencyBand} />
            </div>
            <div className={horizontalStackStyles.item}>
                <FormattedMessage
                    id="AddDevBoxFormRegionControls_Dropdown_RegionName"
                    defaultMessage="{region} | latency: {latencyBand}"
                    description="Dropdown option text to show regions and latency."
                    values={regionValues}
                />
            </div>
        </div>
    );
};

export default RegionDropdownItem;

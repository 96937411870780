import {
    FontSizes,
    FontWeights,
    IContextualMenuItem,
    makeStyles as legacyMakeStyles,
    TooltipHost,
    TooltipOverflowMode,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDynamicMakeStyles } from '../../hooks/styling';
import { CssSelector } from '../../themes/constants/css-selectors';
import {
    useHorizontalStackStyles,
    useStackStyles,
    useStackWithFullWidthItemStyles,
} from '../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../utilities/styles';
import MetadataList, { ShimmeredMetadataList } from '../common/metadata/metadata-list';
import { MetadataItemViewModel, ShimmeredMetadataItemViewModel } from '../common/metadata/models';
import { CardBanner } from './card-banner';
import { ResourceCardBannerViewModel } from './models';
import PrimaryActionsButton from './primary-actions-button';
import SecondaryActionsButton from './secondary-actions-button';

interface ResourceCardProps {
    arePrimaryActionsDisabled?: boolean;
    areSecondaryActionsDisabled?: boolean;
    metadata?: MetadataItemViewModel[];
    name: string;
    stateElement: React.ReactNode;
    statusImageElement?: React.ReactNode;
    previewImageSrc: string;
    projectName: string;
    primaryActions?: IContextualMenuItem[];
    secondaryActions?: IContextualMenuItem[];
    useTranslucentPreviewImage?: boolean;
    displayStateAtTheBottom?: boolean;
    isShimmered?: boolean;
    shimmeredMetadata?: ShimmeredMetadataItemViewModel[];
    cardBanner?: ResourceCardBannerViewModel;
}

interface ResourceCardPreviewImageProps {
    name: string;
    previewImageSrc: string;
    useTranslucentPreviewImage?: boolean;
    statusImageElement?: React.ReactNode;
}

interface ResourceCardNameDisplayProps {
    name: string;
    projectName: string;
}

interface ResourceCardFooterProps {
    arePrimaryActionsDisabled?: boolean;
    stateElement: React.ReactNode;
    primaryActions?: IContextualMenuItem[];
    displayStateAtTheBottom?: boolean;
}

interface ResourceCardBannerProps {
    useTranslucentPreviewImage?: boolean;
    cardBanner?: ResourceCardBannerViewModel;
}

const messages = defineMessages({
    previewImageAlt: {
        id: 'ResourceCard_PreviewImage_Alt',
        defaultMessage: 'Preview for "{name}"',
        description: 'Alt text for preview image. {name} should not be localized.',
    },
});

/**
 * Styles
 */

const resourceCardStylesFactory = (useTranslucentPreviewImage?: boolean) =>
    legacyMakeStyles((theme) => ({
        bannerContainer: {
            position: 'absolute',
            textAlign: 'left',
            width: '100%',
            zIndex: 1,
        },
        previewImage: {
            height: '100%',
            // objectFit: 'cover' downscales image without warping aspect ratio
            objectFit: 'cover',
            opacity: useTranslucentPreviewImage ? '50%' : '100%',
            width: '100%',
        },
        root: {
            backgroundColor: getSemanticColor(theme, 'resourceCardBackground'),
            borderRadius: 4,
            // Using hidden overflow will ensure preview image doesn't cover rounded corners
            overflow: 'hidden',
            position: 'relative',
            height: 392,
            width: 256,

            [CssSelector.ScreenSizeSmallAndBelow]: {
                width: '100%',
            },
        },
        statusImageContainer: {
            bottom: 6,
            left: 24,
            position: 'absolute',
        },
    }));

const useResourceCardContentStyles = makeStyles({
    root: {
        height: '100%',
    },
});

const useStateContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        lineHeight: '16px',
    },
    offset: {
        fontSize: FontSizes.size12,
        lineHeight: '16px',
        marginBottom: '10px',
    },
});

const useContentStyles = makeStyles({
    root: {
        textAlign: 'left',
        gap: '8px',
        height: '100%',
        padding: '0 8px 0 24px',
    },
});

const useHeaderContainerStyles = makeStyles({
    root: {
        padding: '14px 0 0 0',
        marginBottom: '8px',
    },
});

const useHeaderStyles = makeStyles({
    root: {
        width: '100%',
        gap: '8px',
        alignItems: 'start',
    },
});

const useNameColumnContainerStyles = makeStyles({
    root: {
        // Bug #1548954: setting a min width value for this element allows it to shrink. Without this value, the element
        // will ALWAYS be the width of the unwrapped contained text, so long dev box names won't be truncated with ...
        //      https://dev.azure.com/devdiv/OnlineServices/_workitems/edit/1548954
        minWidth: 0,
        flexGrow: 1,
    },
});

const useNameColumnStyles = makeStyles({
    root: {
        gap: '2px',
    },
});

const useNameStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
    },
});

const useNameTooltipHostStyles = makeStyles({
    root: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const usePreviewStyles = makeStyles({
    root: {
        height: '134px',
        // Explicitly assigning relative positioning so status can overlay it
        position: 'relative',
        width: '100%',
    },
});

const useProjectNameStyles = makeStyles({
    root: {
        fontSize: FontSizes.size10,
        fontWeight: FontWeights.semibold,
        lineHeight: '14px',
    },
});

const useMetadataListStyles = makeStyles({
    root: {
        gap: '10px',
    },
});

const useFooterStyles = makeStyles({
    root: {
        gap: '15px',
        justifyContent: 'end',
        height: '100%',
    },
});

const useFooterContainerStyles = makeStyles({
    root: {
        flexGrow: 1,
        paddingBottom: '12px',
    },
});

const useContentContainerStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

/**
 * END Styles
 */

const ResourceCardPreviewImage: React.FC<ResourceCardPreviewImageProps> = React.memo(
    (props: ResourceCardPreviewImageProps) => {
        const { name, previewImageSrc, useTranslucentPreviewImage, statusImageElement } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const previewStyles = usePreviewStyles();
        const useResourceCardStyles = useDynamicMakeStyles(resourceCardStylesFactory, useTranslucentPreviewImage);
        const resourceCardStyles = useResourceCardStyles();
        const stackStyles = useStackStyles();

        return (
            <div className={mergeClasses(stackStyles.item, previewStyles.root)}>
                <img
                    alt={formatMessage(messages.previewImageAlt, { name })}
                    className={resourceCardStyles.previewImage}
                    src={previewImageSrc}
                />

                {statusImageElement && (
                    <span className={resourceCardStyles.statusImageContainer}>{statusImageElement}</span>
                )}
            </div>
        );
    }
);

const ResourceCardNameDisplay: React.FC<ResourceCardNameDisplayProps> = React.memo(
    (props: ResourceCardNameDisplayProps) => {
        const { name, projectName } = props;

        // Style hooks
        const nameColumnContainerStyles = useNameColumnContainerStyles();
        const nameColumnStyles = useNameColumnStyles();
        const nameStyles = useNameStyles();
        const nameTooltipHostStyles = useNameTooltipHostStyles();
        const projectNameStyles = useProjectNameStyles();
        const stackStyles = useStackWithFullWidthItemStyles();
        const horizontalStackStyles = useHorizontalStackStyles();

        return (
            <div className={mergeClasses(horizontalStackStyles.item, nameColumnContainerStyles.root)}>
                <div className={mergeClasses(stackStyles.root, nameColumnStyles.root)}>
                    <div className={mergeClasses(stackStyles.item, nameStyles.root)}>
                        <TooltipHost
                            content={name}
                            overflowMode={TooltipOverflowMode.Self}
                            styles={nameTooltipHostStyles}
                        >
                            {name}
                        </TooltipHost>
                    </div>

                    <div className={mergeClasses(stackStyles.item, projectNameStyles.root)}>{projectName}</div>
                </div>
            </div>
        );
    }
);

const ResourceCardHeader: React.FC<React.PropsWithChildren<unknown>> = React.memo(
    (props: React.PropsWithChildren<unknown>) => {
        const { children } = props;

        const headerContainerStyles = useHeaderContainerStyles();
        const headerStyles = useHeaderStyles();
        const horizontalStackStyles = useHorizontalStackStyles();
        const stackStyles = useStackWithFullWidthItemStyles();

        return (
            <div className={mergeClasses(stackStyles.item, headerContainerStyles.root)}>
                <div className={mergeClasses(horizontalStackStyles.root, headerStyles.root)}>{children}</div>
            </div>
        );
    }
);

const ResourceCardContent: React.FC<React.PropsWithChildren<unknown>> = React.memo(
    (props: React.PropsWithChildren<unknown>) => {
        const { children } = props;

        // Style hooks
        const contentStyles = useContentStyles();
        const wideStackStyles = useStackWithFullWidthItemStyles();
        const stackStyles = useStackStyles();
        const growStyles = useContentContainerStyles();

        return (
            <div className={mergeClasses(wideStackStyles.item, growStyles.root)}>
                <div className={mergeClasses(stackStyles.root, contentStyles.root)}>{children}</div>
            </div>
        );
    }
);

export const ResourceCardState: React.FC<ResourceCardFooterProps> = React.memo((props: ResourceCardFooterProps) => {
    const { displayStateAtTheBottom, stateElement, primaryActions, arePrimaryActionsDisabled } = props;

    const showPrimaryActionsButton = primaryActions && primaryActions.length > 0;

    // Style hooks
    const stateContainerStylesOptions = useStateContainerStyles();
    const stateContainerStyles = showPrimaryActionsButton
        ? stateContainerStylesOptions.root
        : stateContainerStylesOptions.offset;
    const stackStyles = useStackStyles();
    const footerStyles = useFooterStyles();
    const footerContainerStyles = useFooterContainerStyles();

    return (
        <>
            {!displayStateAtTheBottom && (
                <div className={mergeClasses(stackStyles.item, stateContainerStyles)}>{stateElement}</div>
            )}

            {/* 
                NOTE: primary actions in this separate stack allows for auto-spacing that
                pushes these elements to the bottom of the card.
            */}
            <div className={mergeClasses(stackStyles.item, footerContainerStyles.root)}>
                <div className={mergeClasses(stackStyles.root, footerStyles.root)}>
                    {displayStateAtTheBottom && (
                        <div className={mergeClasses(stackStyles.item, stateContainerStyles)}>{stateElement}</div>
                    )}

                    {showPrimaryActionsButton && (
                        <div className={mergeClasses(stackStyles.item)}>
                            <PrimaryActionsButton actions={primaryActions} disabled={arePrimaryActionsDisabled} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
});

export const ResourceCardBanner: React.FC<ResourceCardBannerProps> = React.memo((props: ResourceCardBannerProps) => {
    const { useTranslucentPreviewImage, cardBanner } = props;

    const useResourceCardStyles = useDynamicMakeStyles(resourceCardStylesFactory, useTranslucentPreviewImage);
    const resourceCardStyles = useResourceCardStyles();

    if (cardBanner) {
        return (
            <div className={resourceCardStyles.bannerContainer}>
                <CardBanner cardBanner={cardBanner} />
            </div>
        );
    }

    return <></>;
});

export const ResourceCard: React.FC<ResourceCardProps> = React.memo((props: ResourceCardProps) => {
    const {
        areSecondaryActionsDisabled,
        metadata,
        name,
        statusImageElement,
        previewImageSrc,
        projectName,
        secondaryActions,
        useTranslucentPreviewImage,
        isShimmered,
        shimmeredMetadata,
    } = props;

    const showSecondaryActionsButton = secondaryActions && secondaryActions.length > 0;

    // Style hooks
    const useResourceCardStyles = useDynamicMakeStyles(resourceCardStylesFactory, useTranslucentPreviewImage);
    const resourceCardStyles = useResourceCardStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const stackStyles = useStackStyles();
    const resourceCardContentStyles = useResourceCardContentStyles();

    return (
        <div className={resourceCardStyles.root}>
            {!isShimmered && <ResourceCardBanner {...props} />}

            <div className={mergeClasses(stackStyles.root, resourceCardContentStyles.root)}>
                <ResourceCardPreviewImage
                    name={name}
                    previewImageSrc={previewImageSrc}
                    useTranslucentPreviewImage={useTranslucentPreviewImage}
                    statusImageElement={statusImageElement}
                />

                <ResourceCardContent>
                    <ResourceCardHeader>
                        <ResourceCardNameDisplay name={name} projectName={projectName} />

                        {!isShimmered && showSecondaryActionsButton && (
                            <div className={horizontalStackStyles.item}>
                                <SecondaryActionsButton
                                    disabled={areSecondaryActionsDisabled}
                                    actions={secondaryActions}
                                    resourceName={name}
                                />
                            </div>
                        )}
                    </ResourceCardHeader>

                    {isShimmered ? (
                        <ShimmeredMetadataList metadataList={shimmeredMetadata} />
                    ) : (
                        <MetadataList metadataList={metadata} useContainerStyles={useMetadataListStyles} />
                    )}

                    {!isShimmered && <ResourceCardState {...props} />}
                </ResourceCardContent>
            </div>
        </div>
    );
});

export default ResourceCard;

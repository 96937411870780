import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { FeatureFlagName } from '../../../constants/features';
import { Property } from '../../../constants/telemetry';
import { TenantId } from '../../../constants/tenant';
import { isAuthenticated } from '../../../data/services/identity';
import { initialize as initializePermissionWorkerPool } from '../../../data/services/web-worker/permission-worker-pool';
import { ClientError, FailureOperation } from '../../../models/common';
import { isLocalEnvironment } from '../../../utilities/app';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { areStringsEquivalent } from '../../../utilities/string';
import {
    initializeApplication,
    initializeApplicationError,
    initializeApplicationSuccess,
    syncDismissedContent,
} from '../../actions/application/application-action-creators';
import { enableFeatures, freezeFeatures, initializeFeatures } from '../../actions/features/features-action-creators';
import { initializeAuthenticationState } from '../../actions/identity/identity-action-creators';
import { initializeLocalization } from '../../actions/localization/localization-action-creators';
import { initializeMocks } from '../../actions/mocks/mocks-action-creators';
import { initializeStorage } from '../../actions/storage/storage-action-creators';
import { setGlobalProperty } from '../../actions/telemetry/telemetry-action-creators';
import { syncUserSettings } from '../../actions/user-settings/user-settings-action-creator';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeLeading } from '../../effects/take';
import { getEnabledFeatureFlagNames } from '../../selector/features-selectors';
import { getHomeTenantId, getTenantId, getUsername } from '../../selector/identity-selectors';

export function* initializeApplicationSaga(): SagaIterator {
    try {
        // Initialize features, browser storage, localization data, mocks, and permission web worker
        yield all([
            putAndAwait(initializeFeatures()),
            putAndAwait(initializeStorage()),
            putAndAwait(initializeLocalization()),
            putAndAwait(initializeMocks()),
        ]);

        const isDiscoveryServiceEnabled: boolean = yield call(
            isFeatureFlagEnabled,
            FeatureFlagName.EnableDiscoveryService
        );

        if (!isDiscoveryServiceEnabled) {
            yield call(initializePermissionWorkerPool);
        }

        // Initialize authentication
        // TODO: allow routes to opt out of silent SSO or redirect on SSO failure by passing arguments to below action
        yield putAndAwait(initializeAuthenticationState({ useSilentSso: true }));

        // enable and freeze certain features for local/dev and devportal.*.com envs
        const enforcedFeatures: FeatureFlagName[] = [
            // Enable experimental features when running locally
            ...(isLocalEnvironment ? [FeatureFlagName.UnderDevelopment] : []),
        ];

        if (enforcedFeatures.length > 0) {
            yield put(freezeFeatures({ features: enforcedFeatures }));
            yield put(enableFeatures({ features: enforcedFeatures }));
        }

        // After enabling/freezing features, get and log the currently configured feature flags
        const enabledFeatures: FeatureFlagName[] = yield select(getEnabledFeatureFlagNames);
        yield put(setGlobalProperty({ propertyName: Property.FeatureFlags, value: enabledFeatures }));

        // If the user is signed in, issue a request to get the user's names, profile picture, and signed-in org branding
        const isSignedIn = yield call(isAuthenticated);
        if (isSignedIn) {
            // Now that we know the tenant the user is signed into:
            //      - set the signed-in tenant globally across all telemetry
            //      - if this user is homed to the MSFT tenant and signed into it, set the UPN globally
            const homeTenantId: string = yield select(getHomeTenantId);
            const tenantId: string = yield select(getTenantId);

            yield put(setGlobalProperty({ propertyName: Property.HomeTenantId, value: homeTenantId }));
            yield put(setGlobalProperty({ propertyName: Property.TenantId, value: tenantId }));

            if (
                areStringsEquivalent(homeTenantId, TenantId.Microsoft, true) &&
                areStringsEquivalent(tenantId, TenantId.Microsoft, true)
            ) {
                const username: string = yield select(getUsername);
                yield put(setGlobalProperty({ propertyName: Property.Username, value: username }));
            }

            // Fire-and-forget: initialize info messages state (dependent on storage and authentication)
            yield put(syncDismissedContent());

            yield put(syncUserSettings());
        }

        yield put(initializeApplicationSuccess());
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeApplication);
        yield put(initializeApplicationError({ error }));
    }
}

export function* initializeApplicationListenerSaga(): SagaIterator {
    // Ensure that we don't invoke initialization procedures more than once
    yield takeLeading(initializeApplication, initializeApplicationSaga);
}

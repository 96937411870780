import { CapitalizedUnionMap, CapitalizedUnionValueMap, UnionMap } from '../types/union-map';

export type Environment = 'development' | 'local' | 'public';

export const Environment: CapitalizedUnionMap<Environment> = {
    Development: 'development',
    Local: 'local',
    Public: 'public',
};

export type EnvironmentShortName = 'dev' | 'local' | 'public';

export const EnvironmentShortName: CapitalizedUnionValueMap<Environment, EnvironmentShortName> = {
    Development: 'dev',
    Local: 'local',
    Public: 'public',
};

export type PanelName =
    | 'AddDevBox'
    | 'AddEnvironment'
    | 'DevMenu'
    | 'ErrorDetails'
    | 'HelpMenu'
    | 'DevBoxDetails'
    | 'RedeployEnvironment'
    | 'EnvironmentDetails'
    | 'CustomizationDetails'
    | 'Support'
    | 'UserSettings';

export const PanelName: UnionMap<PanelName> = {
    AddDevBox: 'AddDevBox',
    AddEnvironment: 'AddEnvironment',
    DevMenu: 'DevMenu',
    ErrorDetails: 'ErrorDetails',
    HelpMenu: 'HelpMenu',
    DevBoxDetails: 'DevBoxDetails',
    RedeployEnvironment: 'RedeployEnvironment',
    EnvironmentDetails: 'EnvironmentDetails',
    CustomizationDetails: 'CustomizationDetails',
    Support: 'Support',
    UserSettings: 'UserSettings',
};

export type DialogName =
    | 'ConfigureRemoteDesktop'
    | 'DelayShutdown'
    | 'OpenWindowsDesktopClient'
    | 'Confirmation'
    | 'RedeployEnvironment'
    | 'HibernatePreview'
    | 'Repair'
    | 'ChangeEnvironmentExpiration'
    | 'ConnectViaApp';

export const DialogName: UnionMap<DialogName> = {
    ConfigureRemoteDesktop: 'ConfigureRemoteDesktop',
    DelayShutdown: 'DelayShutdown',
    OpenWindowsDesktopClient: 'OpenWindowsDesktopClient',
    Confirmation: 'Confirmation',
    RedeployEnvironment: 'RedeployEnvironment',
    HibernatePreview: 'HibernatePreview',
    Repair: 'Repair',
    ChangeEnvironmentExpiration: 'ChangeEnvironmentExpiration',
    ConnectViaApp: 'ConnectViaApp',
};

export type SurfaceName = PanelName | DialogName;

export type DismissableContentName =
    | 'HibernatePreviewDialog'
    | 'PlannedMaintenance20241004Banner'
    | 'PortalSurveyInfoBanner'
    | 'SettingsMenuTeachableBubble'
    | 'ConnectViaAppTeachableBubble';

export const DismissableContentName: UnionMap<DismissableContentName> = {
    HibernatePreviewDialog: 'HibernatePreviewDialog',
    PlannedMaintenance20241004Banner: 'PlannedMaintenance20241004Banner',
    PortalSurveyInfoBanner: 'PortalSurveyInfoBanner',
    SettingsMenuTeachableBubble: 'SettingsMenuTeachableBubble',
    ConnectViaAppTeachableBubble: 'ConnectViaAppTeachableBubble',
};

export type QuickActionName = 'ConfigureRemoteDesktop';

export const QuickActionName: UnionMap<QuickActionName> = {
    ConfigureRemoteDesktop: 'ConfigureRemoteDesktop',
};

export type SearchParameter =
    | 'features'
    | 'locale'
    | 'mockScenarios'
    | 'signInTenantId'
    | 'resource'
    | 'project'
    | 'devCenterUri';

export const SearchParameter: CapitalizedUnionMap<SearchParameter> = {
    Features: 'features',
    Locale: 'locale',
    MockScenarios: 'mockScenarios',
    SignInTenantId: 'signInTenantId',
    Resource: 'resource',
    Project: 'project',
    DevCenterUri: 'devCenterUri',
};

import { ChoiceGroup, IChoiceGroupOption, Separator } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { Field } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';
import { RemoteAppOption } from '../../../types/user-settings';
import { UserSettingsFormFieldName } from '../models';
import ConnectViaAppDownloadButton from './connect-via-app-download-button';
import ConnectViaAppDownloadCard from './connect-via-app-download-card';

const messages = defineMessages({
    connectViaWindowsAppText: {
        id: 'SettingsFormFieldGroup_ConnectViaWindowsApp_Text',
        defaultMessage: 'Connect via Windows App',
        description: 'Label for choice group option "Connect via Windows App"',
    },
    connectViaRemoteDesktopLegacyText: {
        id: 'SettingsFormFieldGroup_ConnectViaRemoteDesktopLegacy_Text',
        defaultMessage: 'Connect via Remote Desktop (legacy)',
        description: 'Label for choice group option "Connect via Remote Desktop (legacy)"',
    },
    useSingleMonitorText: {
        id: 'SettingsFormFieldGroup_UseSingleMonitor_Text',
        defaultMessage: 'Use a single monitor',
        description: 'Label for choice group option "Use a single monitor"',
    },
    useMultiMonitorText: {
        id: 'SettingsFormFieldGroup_UseMultiMonitor_Text',
        defaultMessage: 'Use multiple monitors',
        description: 'Label for choice group option "Use a multiple monitors"',
    },
});

/**
 * Styles
 */

const useCheckboxStyles = makeStyles({
    root: {
        marginBottom: '16px',
        marginTop: '16px',
    },
});

const useFooterStyles = makeStyles({
    root: {
        justifyContent: 'start',
    },
});

/**
 * END Styles
 */

export const ConnectViaAppFormFieldGroup: React.FC = () => {
    // Style hooks
    const checkboxStyles = useCheckboxStyles();
    const stackStyles = useStackStyles();
    const footerStyles = useFooterStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    // Intl hooks
    const { formatMessage } = useIntl();
    const [preferredRemoteApp, setPreferredRemoteApp] = React.useState(RemoteAppOption.useWindowsApp);

    const connectionOptions: IChoiceGroupOption[] = React.useMemo(
        () => [
            { key: RemoteAppOption.useWindowsApp, text: formatMessage(messages.connectViaWindowsAppText) },
            { key: RemoteAppOption.useAvd, text: formatMessage(messages.connectViaRemoteDesktopLegacyText) },
        ],
        [formatMessage]
    );

    const monitorOptions: IChoiceGroupOption[] = React.useMemo(
        () => [
            { key: 'false', text: formatMessage(messages.useSingleMonitorText) },
            { key: 'true', text: formatMessage(messages.useMultiMonitorText) },
        ],
        [formatMessage]
    );

    return (
        <div className={stackStyles.root}>
            <div className={stackStyles.item}>
                <Field name={UserSettingsFormFieldName.preferredRemoteApp}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { onChange } = input;

                        const onPreferredRemoteAppChange = React.useCallback(
                            (_event: React.FormEvent<HTMLElement> | undefined, option?: IChoiceGroupOption) => {
                                onChange(option?.key);
                                setPreferredRemoteApp(option?.key as RemoteAppOption);
                            },
                            [onChange]
                        );
                        return (
                            <ChoiceGroup
                                options={connectionOptions}
                                onChange={onPreferredRemoteAppChange}
                                styles={checkboxStyles}
                                defaultSelectedKey={RemoteAppOption.useWindowsApp}
                            />
                        );
                    }}
                </Field>
            </div>
            <div className={stackStyles.item}>
                <ConnectViaAppDownloadCard
                    contentFooter={
                        <div className={mergeClasses(horizontalStackStyles.root, footerStyles.root)}>
                            <div className={horizontalStackStyles.item}>
                                <ConnectViaAppDownloadButton remoteAppOption={preferredRemoteApp} />
                            </div>
                        </div>
                    }
                    paddingBottom={20}
                    subtitle={
                        preferredRemoteApp === RemoteAppOption.useWindowsApp ? (
                            <FormattedMessage
                                id="WindowsApp_DownloadCardSubtitle_Text"
                                defaultMessage="If the Windows App is not installed, get it from the Microsoft Store."
                                description="Subtitle for the download option within the 'connect via windows app' dialog"
                            />
                        ) : (
                            <FormattedMessage
                                id="RemoteDesktop_DownloadCardSubtitle_Text"
                                defaultMessage="If the Remote Desktop is not installed, get it from the Microsoft Store"
                                description="Subtitle for the download option within the 'connect via windows app' dialog"
                            />
                        )
                    }
                />
            </div>
            <Separator />
            <div className={stackStyles.item}>
                <Field name={UserSettingsFormFieldName.useMultiMonitor}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { onChange } = input;

                        const onMultiMonitorChange = React.useCallback(
                            (_event: React.FormEvent<HTMLElement> | undefined, option?: IChoiceGroupOption) => {
                                const selectedKey = option?.key === 'true';
                                onChange(selectedKey);
                            },
                            [onChange]
                        );

                        return (
                            <ChoiceGroup
                                options={monitorOptions}
                                onChange={onMultiMonitorChange}
                                styles={checkboxStyles}
                                defaultSelectedKey={false.toString()}
                            />
                        );
                    }}
                </Field>
            </div>
        </div>
    );
};

import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
    Spinner,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../../hooks/action-creator';
import { useRedeployEnvironmentDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { usePrevious } from '../../../hooks/use-previous';
import { Status } from '../../../models/common';
import { Environment } from '../../../models/environment';
import { deployEnvironment } from '../../../redux/actions/environment/environment-action-creators';
import { isStatusInProgress, isStatusTerminal } from '../../../redux/selector/common';
import { getStatusForDeployEnvironment } from '../../../redux/selector/environment-selectors';
import { ReturnVoid } from '../../../types/return-void';

export interface RedeployEnvironmentDialogComponentProps extends RedeployEnvironmentDialogProps {
    onDeployEnvironment: ReturnVoid<typeof deployEnvironment>;
    statusForDeployEnvironment: Status;
}

const messages = defineMessages({
    redeployDialogPrimaryButtonText: {
        id: 'EnvironmentCard_RedeployDialogPrimaryButton_Text',
        defaultMessage: 'Redeploy',
        description: 'Confirmation button to redeploy the environment',
    },
    closeButtonAriaLabel: {
        id: 'RedeployEnvironmentDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss dialog',
        description: "Aria label for the redeploy dialog's close button",
    },
    cancelButtonText: {
        id: 'RedeployEnvironmentDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Cancel button to dismiss the redeploy dialog and perform no operation',
    },
});

export const RedeployEnvironmentDialogComponent: React.FC<RedeployEnvironmentDialogComponentProps> = (
    props: RedeployEnvironmentDialogComponentProps
) => {
    const { environment, open, onDeployEnvironment, onDismiss, statusForDeployEnvironment } = props;
    const { environmentDefinitionName, name, uri } = environment;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // detect when we should close ourselves
    const previousStatusForDeployEnvironment = usePrevious(statusForDeployEnvironment);
    React.useEffect(() => {
        if (isStatusInProgress(previousStatusForDeployEnvironment) && isStatusTerminal(statusForDeployEnvironment)) {
            onDismiss();
        }
    }, [previousStatusForDeployEnvironment, statusForDeployEnvironment, onDismiss]);

    // Memoized data
    const isSubmitting = isStatusInProgress(statusForDeployEnvironment);

    const subTextValues = React.useMemo(() => ({ environmentDefinitionName }), [environmentDefinitionName]);
    const titleValues = React.useMemo(() => ({ environmentName: name }), [name]);

    const onPrimaryButtonClicked = React.useCallback(() => {
        onDeployEnvironment({
            environment,
            id: uri,
            // Note: dialog will close on initial request completion, regardless of error, failure, or success.
            // Therefore, we want to make sure any error/failure is shown on the resource card.
            showPutErrorOnResource: true,
        });
    }, [onDeployEnvironment, uri, environment]);

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={open} onOpenChange={isSubmitting ? undefined : onDismiss}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
                                </DialogTrigger>
                            }
                        >
                            <FormattedMessage
                                id="EnvironmentCard_RedeployDialogTitle_Text"
                                defaultMessage="Redeploy {environmentName}"
                                description="Header text for environment redeploy dialog. {environmentName} should not be localized, it is the name of the environment to be redeployed."
                                values={titleValues}
                            />
                        </DialogTitle>
                        <DialogContent>
                            <FormattedMessage
                                id="EnvironmentCard_RedeployDialogSubText_Text"
                                defaultMessage="The environment definition {environmentDefinitionName} has no parameters, and will be redeployed immediately."
                                description="Informs the user that their environment will be redeployed. {environmentDefinitionName} should not be localized, it is the name of the environment definition."
                                values={subTextValues}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                aria-label={formatMessage(messages.redeployDialogPrimaryButtonText)}
                                onClick={onPrimaryButtonClicked}
                                disabled={isSubmitting}
                            >
                                {isSubmitting && <Spinner size="tiny" />}
                                {!isSubmitting && (
                                    <FormattedMessage
                                        id="EnvironmentCard_RedeployDialogPrimaryButton_Text"
                                        defaultMessage="Redeploy"
                                        description="Confirmation button to redeploy the environment"
                                    />
                                )}
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    appearance="secondary"
                                    aria-label={formatMessage(messages.cancelButtonText)}
                                    disabled={isSubmitting}
                                >
                                    <FormattedMessage
                                        id="RedeployEnvironmentDialog_CancelButton_Text"
                                        defaultMessage="Cancel"
                                        description="Cancel button to dismiss the redeploy dialog and perform no operation"
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export interface RedeployEnvironmentDialogProps {
    open?: boolean;
    onDismiss: () => void;
    environment: Environment;
}

export const RedeployEnvironmentDialogContainer: React.FC<RedeployEnvironmentDialogProps> = (
    props: RedeployEnvironmentDialogProps
) => {
    const statusForDeployEnvironment = useSelector(getStatusForDeployEnvironment);

    // Action hooks
    const onDeployEnvironment = useActionCreator(deployEnvironment);

    return (
        <RedeployEnvironmentDialogComponent
            statusForDeployEnvironment={statusForDeployEnvironment}
            onDeployEnvironment={onDeployEnvironment}
            {...props}
        />
    );
};

export const RedeployEnvironmentDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useRedeployEnvironmentDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <RedeployEnvironmentDialogContainer {...properties} open={isOpen} onDismiss={closeSurface} />;
};

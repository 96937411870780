import { IntrinsicTask, IntrinsicTaskHintDefaultValue } from '../../../constants/customization';
import { createDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { AzureDevOpsBranch, AzureDevOpsRepo } from '../../../models/azure-dev-ops';
import { SerializableMap } from '../../../types/serializable-map';
import { compact, sortByInPlace } from '../../../utilities/array';
import { getAzureDevOpsRepoItemId } from '../../../utilities/azure-dev-ops';
import { get } from '../../../utilities/serializable-map';
import { areStringsEquivalent, compareStrings, isUndefinedOrEmpty } from '../../../utilities/string';
import { ImageViewModel, LatencyBand, RegionViewModel, RegionViewModelMap } from '../models';
import { IntrinsicTaskDescriptionMessages, IntrinsicTaskHintMessages } from './messages';
import { DevBoxNameFieldErrorType, IntrinsicTaskWithDescription } from './models';

// Note: below regex is borrowed from API spec.
// https://devdiv.visualstudio.com/DefaultCollection/OnlineServices/_git/azure-devcenter?path=/src/sdk/specification/devcenter/data-plane/Microsoft.DevCenter/preview/2023-07-01-preview/devbox.json&version=GBmain&line=2317&lineEnd=2317&lineStartColumn=7&lineEndColumn=54&lineStyle=plain&_a=contents
const DevBoxNameFormat = /^[a-zA-Z0-9][a-zA-Z0-9-_\.]*[a-zA-Z0-9-_]$/;
const DevBoxNameMaximumLength = 63;
const DevBoxNameMinimumLength = 3;

export const getDevBoxNameFieldErrorType = (
    value: string,
    projectId: string | undefined,
    user: string,
    existingDevBoxIdentifiers: string[]
): DevBoxNameFieldErrorType => {
    // Note: doing this check separately and first to prevent nullrefs at runtime
    if (isUndefinedOrEmpty(value)) {
        return DevBoxNameFieldErrorType.TooShort;
    }

    if (!DevBoxNameFormat.test(value)) {
        return DevBoxNameFieldErrorType.InvalidFormat;
    }

    if (value.length < DevBoxNameMinimumLength) {
        return DevBoxNameFieldErrorType.TooShort;
    }

    if (value.length > DevBoxNameMaximumLength) {
        return DevBoxNameFieldErrorType.TooLong;
    }

    // Don't check for an existing dev box name unless a project has been selected
    if (projectId) {
        const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(projectId);
        const devBoxId = createDevBoxDataPlaneUri({ devBoxName: value, devCenter, projectName, user });

        // Note: dev boxes are expected to be unique per project and owner. As the Dev Portal is presently focused on showing users
        // only the dev boxes that they own, we assume all dev boxes identified within a project during discovery belong to the
        // signed-in user. However, if we ever have scenarios where we load dev boxes that don't belong to the signed-in user,
        // we should add logic below that filters the returned list to just the names of dev boxes the user is an owner of.
        if (existingDevBoxIdentifiers.some((existing) => areStringsEquivalent(existing, devBoxId))) {
            return DevBoxNameFieldErrorType.AlreadyExists;
        }
    }

    return DevBoxNameFieldErrorType.None;
};

export const isRepoUriValidFormat = (value: string): boolean => {
    let url: URL;
    try {
        url = new URL(value);
    } catch (error) {
        return false;
    }

    return !!url;
};

export const getValueForRepoItem = <TValue>(
    values: SerializableMap<TValue>,
    repo: AzureDevOpsRepo | undefined,
    branch: AzureDevOpsBranch | undefined,
    filePath: string | undefined
): TValue | undefined => {
    if (!repo || !branch || !filePath) {
        return undefined;
    }

    const repoItemPathIdentifier = getAzureDevOpsRepoItemId(repo.url, branch.name, filePath);

    return get(values, repoItemPathIdentifier);
};

export const getDefaultBranch = (
    branches: SerializableMap<AzureDevOpsBranch[]>,
    repo: AzureDevOpsRepo
): AzureDevOpsBranch | undefined => {
    const branchesForRepo = get(branches, repo.url);

    if (!repo?.defaultBranch || !branchesForRepo) {
        return undefined;
    }

    return branchesForRepo.find((branch) => branch.name === repo.defaultBranch);
};

export const getIntrinsicTaskItems = (
    intrinsicTasks: IntrinsicTask[],
    devBoxName?: string
): IntrinsicTaskWithDescription[] => {
    const intrinsicTaskItems: IntrinsicTaskWithDescription[] = [];
    intrinsicTasks.forEach((task) => {
        switch (task) {
            case IntrinsicTask.UpdatePcName: {
                const updatePcNameIntrinsicTask: IntrinsicTaskWithDescription = {
                    key: IntrinsicTask.UpdatePcName,
                    description: IntrinsicTaskDescriptionMessages.UpdatePcName,
                    hint: IntrinsicTaskHintMessages.UpdatePcName,
                    // If devBoxName is not provided, use a default value to display in the hint instead of an empty string
                    hintParameters: {
                        devBoxName: isUndefinedOrEmpty(devBoxName)
                            ? IntrinsicTaskHintDefaultValue.DevBoxName
                            : devBoxName,
                    },
                };

                intrinsicTaskItems.push(updatePcNameIntrinsicTask);
                break;
            }
            default:
                break;
        }
    });

    return intrinsicTaskItems;
};

export const getHasOptimalRegions = (regions: RegionViewModel[]): boolean => {
    const optimalRegions = regions.filter((region) => region.latencyBand !== LatencyBand.Poor);

    return optimalRegions.length > 0;
};

export const getRegionsToRegionRecommendations = (
    images: ImageViewModel[],
    regionRecommendations: RegionViewModelMap
): RegionViewModel[] => {
    const regionToRegionRecommendations = images.map((image) => {
        const regionRecommendation = get(regionRecommendations, image.region);

        return regionRecommendation;
    });

    return sortByInPlace(
        compact(regionToRegionRecommendations),
        (option) => option.name,
        (a, b) => compareStrings(a, b, true)
    );
};

import { IColumn } from '@fluentui/react';
import { IntlFormatters, MessageDescriptor } from 'react-intl';
import { sortBy } from '../../../utilities/array';
import { compareNumbers } from '../../../utilities/number';
import { compareStrings, isSubstringOfTextCaseInsensitive, isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { getRelativeTimeComponents } from '../../../utilities/time';
import { RegionKey, RegionViewModel } from '../models';
import { selectDevBoxRegionDetailsListMessages } from './messages';
import { RegionDetailsListItem } from './models';

const compareRegionViewModels = (a: RegionViewModel, b: RegionViewModel, columnKey: RegionKey): number => {
    switch (columnKey) {
        case RegionKey.RoundTripTime:
            return compareNumbers(a[columnKey] as number, b[columnKey] as number);

        // For everything else, do a case-insensitive string comparison
        default:
            return compareStrings(`${a[columnKey]}`, `${b[columnKey]}`, true);
    }
};

const createRegionDetailsListColumn = (
    ariaLabel: string,
    isSortedDescending: boolean,
    key: RegionKey,
    keyOfSortedColumn: RegionKey,
    name: string,
    onColumnClick: (ev: unknown, column: IColumn) => void,
    onRender: (item: RegionDetailsListItem, index?: number, column?: IColumn) => JSX.Element | null,
    sortAscendingAriaLabel: string,
    sortDescendingAriaLabel: string,
    minWidth: number,
    maxWidth?: number,
    isPadded = true,
    isRowHeader = false
): IColumn => ({
    ariaLabel,
    isPadded,
    isResizable: minWidth !== maxWidth,
    isRowHeader,
    isSorted: key === keyOfSortedColumn,
    ...(key === keyOfSortedColumn ? { isSortedDescending } : {}),
    key,
    maxWidth,
    minWidth,
    name,
    onColumnClick,
    onRender,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
});

export const getRegionViewModelKey = (value: RegionViewModel): string => value.id;

const createRegionDetailsListItem = (
    formatRoundTripTime: (value: number) => string,
    regionViewModel: RegionViewModel
): RegionDetailsListItem => {
    const { name, latencyBand, roundTripTime } = regionViewModel;

    return {
        key: getRegionViewModelKey(regionViewModel),
        columns: {
            latencyBand: latencyBand ?? '',
            roundTripTime: formatRoundTripTime(roundTripTime) ?? '',
            name,
        },
        value: regionViewModel,
    };
};

const doesRegionMatchSearchText = (searchText: string, pool: RegionDetailsListItem) => {
    const { columns } = pool;
    const { name, latencyBand, roundTripTime } = columns;

    return (
        isSubstringOfTextCaseInsensitive(name, searchText) ||
        isSubstringOfTextCaseInsensitive(latencyBand, searchText) ||
        isSubstringOfTextCaseInsensitive(roundTripTime, searchText)
    );
};

const filterRegionDetailsListItems = (filterText: string, items: RegionDetailsListItem[]): RegionDetailsListItem[] =>
    items.filter((item) => doesRegionMatchSearchText(filterText, item));

export const formatLastRegionUpdate = (lastUpdatedDate: Date | undefined, formatters: IntlFormatters): string => {
    if (lastUpdatedDate === undefined) {
        return '';
    }

    const { formatRelativeTime } = formatters;
    const { unit, value } = getRelativeTimeComponents(lastUpdatedDate, new Date());

    // Note: converting value to negative to get "x days ago" rather than "in x days"
    return formatRelativeTime(value * -1, unit);
};

export const formatRoundTripTime = (roundTripTime: number, formatters: IntlFormatters): string => {
    const { formatMessage, formatNumber } = formatters;

    return formatMessage(selectDevBoxRegionDetailsListMessages.roundTripTimeFormattedText, {
        roundTripTime: formatNumber(roundTripTime),
    });
};

export const getRegionDetailsListColumns = (
    descending: boolean,
    formatMessage: (message: MessageDescriptor) => string,
    keyOfSortedColumn: RegionKey,
    onColumnClick: (ev: unknown, column: IColumn) => void,
    onRenderItem: (item: RegionDetailsListItem, index?: number, column?: IColumn) => JSX.Element | null
): IColumn[] => {
    const sortAscendingAriaLabel = formatMessage(selectDevBoxRegionDetailsListMessages.sortAscendingOrderAriaLabel);
    const sortDescendingAriaLabel = formatMessage(selectDevBoxRegionDetailsListMessages.sortDescendingOrderAriaLabel);

    return [
        createRegionDetailsListColumn(
            formatMessage(selectDevBoxRegionDetailsListMessages.regionColumnHeaderAriaLabel),
            descending,
            RegionKey.Name,
            keyOfSortedColumn,
            formatMessage(selectDevBoxRegionDetailsListMessages.regionColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            200,
            undefined,
            false,
            true
        ),
        createRegionDetailsListColumn(
            formatMessage(selectDevBoxRegionDetailsListMessages.latencyColumnHeaderAriaLabel),
            descending,
            RegionKey.LatencyBand,
            keyOfSortedColumn,
            formatMessage(selectDevBoxRegionDetailsListMessages.latencyColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            150
        ),
        createRegionDetailsListColumn(
            formatMessage(selectDevBoxRegionDetailsListMessages.roundTripTimeColumnHeaderAriaLabel),
            descending,
            RegionKey.RoundTripTime,
            keyOfSortedColumn,
            formatMessage(selectDevBoxRegionDetailsListMessages.roundTripTimeColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            500,
            500
        ),
    ];
};

export const getRegionDetailsListItems = (
    columnKey: RegionKey,
    descending: boolean,
    filterText: string,
    formatRoundTripTime: (value: number) => string,
    poolViewModels: RegionViewModel[]
): RegionDetailsListItem[] => {
    // Sort items by their current given key
    const orderedItems = sortBy(
        poolViewModels,
        (item) => item,
        (a, b) => compareRegionViewModels(a, b, columnKey)
    );
    const sortedItems = descending ? orderedItems.reverse() : orderedItems;

    // Convert view models into formatted items
    const formattedItems = sortedItems.map((item) => createRegionDetailsListItem(formatRoundTripTime, item));

    // Apply filter
    return isUndefinedOrWhiteSpace(filterText)
        ? formattedItems
        : filterRegionDetailsListItems(filterText, formattedItems);
};

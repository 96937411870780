import { makeStyles as legacyMakeStyles } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { AppSemanticColor } from '../../../../../themes/app-semantic-colors';
import { getSemanticColor } from '../../../../../utilities/styles';
import { ImageViewModel } from '../../../../add-dev-box-panel/models';
import { DropdownDividerOption } from '../divider';
import { ValueDropdown, ValueDropdownOption, ValueDropdownWrapperProps } from '../value-dropdown';
import { getOptionKeyForIndexedModel } from './selectors';

type DropdownProps = ValueDropdownWrapperProps<ImageViewModel>;

/**
 * Styles
 */

const useLinkStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, AppSemanticColor.seeAllLink),
    },
}));

/**
 * END Styles
 */

export const ImageDropdown: React.FC<DropdownProps> = (props) => {
    const { options } = props;

    // Style hooks
    const linkStyles = useLinkStyles();

    const onRenderOption = (value: ImageViewModel): JSX.Element => {
        if (value.id === 'see-all-images') {
            return (
                <div className={linkStyles.root}>
                    <FormattedMessage
                        id="AddDevBoxFormImageControls_SeeAllImagesLink_Text"
                        defaultMessage="See all images"
                        description="Text for the see all images link for the add dev box image form field"
                    />
                </div>
            );
        }

        return <div>{value.name}</div>;
    };

    const optionsWithDivider: ValueDropdownOption<ImageViewModel>[] = React.useMemo(() => {
        //If there is only option, don't insert divider.
        if (options.length > 1 && options[options.length - 1]) {
            const optionsWithDivider: ValueDropdownOption<ImageViewModel>[] = options.slice(0, options.length - 1);
            optionsWithDivider.push(DropdownDividerOption('image-divider'), options[options.length - 1]);

            return optionsWithDivider;
        }

        return options;
    }, [options]);

    return (
        <ValueDropdown<ImageViewModel>
            {...props}
            options={optionsWithDivider}
            onRenderOption={onRenderOption}
            getOptionKey={getOptionKeyForIndexedModel}
        />
    );
};

export default ImageDropdown;

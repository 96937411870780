import { FontSizes, FontWeights, Text, makeStyles as legacyMakeStyles } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IntrinsicTask } from '../../constants/customization';
import { FeatureFlagName } from '../../constants/features';
import { useActionCreator } from '../../hooks/action-creator';
import { useAddDevBoxPanelContext } from '../../hooks/context/panels';
import { usePrevious } from '../../hooks/use-previous';
import { createCustomizationGroupDataPlaneUri } from '../../ids/customization-group';
import { createDevBoxDataPlaneUri } from '../../ids/dev-box';
import { getTokensFromProjectDataPlaneUri } from '../../ids/project';
import { Status } from '../../models/common';
import {
    CustomizationTaskDefinition,
    CustomizationTaskListValidationResult,
    CustomizationTaskListValidationStatus,
    PutCustomizationTask,
} from '../../models/customization';
import { ProjectFromDiscoveryService, ProjectResource } from '../../models/project';
import { ScheduleMap } from '../../models/schedule';
import {
    clearValidationResult,
    listCustomizationTaskDefinitions,
    validateCustomizationTasks,
} from '../../redux/actions/customization/customization-action-creators';
import { addDevBox, clearAddDevBoxFailure } from '../../redux/actions/dev-box/dev-box-action-creators';
import {
    isStatusInProgress,
    isStatusNotStarted,
    isStatusSuccessful,
    isStatusTerminal,
    isStatusUnsuccessful,
} from '../../redux/selector/common';
import {
    getCustomizationTaskDefinitions,
    getStatusForListCustomizationTaskDefinitions,
    getStatusForValidateCustomizationTasks,
    getValidateCustomizationTasksResult,
} from '../../redux/selector/customization-selectors';
import { getDevBoxIds, getStatusForAddDevBox } from '../../redux/selector/dev-box-selectors';
import { getDidSomeProjectFailToLoadDevBoxCreateResources } from '../../redux/selector/display/load-state';
import { getObjectId } from '../../redux/selector/identity-selectors';
import { getLocale } from '../../redux/selector/localization-selectors';
import { getProjectsFromDiscoveryServiceAuthorizedForDevBoxCustomize } from '../../redux/selector/project-from-discovery-service-selectors';
import { getProjectsAuthorizedForDevBoxCustomizeByDataPlaneId } from '../../redux/selector/project-selectors';
import { getSchedules } from '../../redux/selector/schedule-selectors';
import { AppSemanticColor } from '../../themes/app-semantic-colors';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import { ReturnVoid } from '../../types/return-void';
import { SerializableMap } from '../../types/serializable-map';
import { isFeatureFlagEnabled } from '../../utilities/features';
import { hasNoValue } from '../../utilities/object';
import { get, has } from '../../utilities/serializable-map';
import { getSemanticColor } from '../../utilities/styles';
import { FormPanel } from '../common/form-panel';
import AddDevBoxFormFieldGroup from './add-dev-box-form-field-group/add-dev-box-form-field-group';
import { CustomizationFileFormFieldGroup } from './add-dev-box-form-field-group/customization-controls/customization-file-form-field-group';
import { DevBoxNameFieldErrorType } from './add-dev-box-form-field-group/models';
import { getDevBoxNameFieldErrorType } from './add-dev-box-form-field-group/selectors';
import AddDevBoxPanelFooter from './add-dev-box-panel-footer';
import AddDevBoxSummary from './add-dev-box-summary/add-dev-box-summary';
import {
    AddDevBoxFormData,
    AddDevBoxFormField,
    AddDevBoxFormProjectViewModel,
    CustomizationData,
    CustomizationGroupName,
    CustomizationPanels,
    ImageViewModel,
    ImageViewModelMap,
    PoolViewModel,
    ProjectToPoolViewModelMap,
    RegionViewModel,
    ScheduleViewModel,
} from './models';
import {
    createInitialValues,
    getImageViewModels,
    getPoolViewModels,
    getProjectFromDiscoveryServiceViewModels,
    getProjectViewModels,
    getRegionViewModels,
    getScheduleViewModel,
} from './selectors';

interface AddDevBoxPanelProps {
    statusForAddDevBox: Status;
    statusForValidateCustomizationTasks: Status;
    devBoxIdentifiers: string[];
    isOpen: boolean;
    onAddDevBoxSubmitted: ReturnVoid<typeof addDevBox>;
    onValidateCustomizationTasks: ReturnVoid<typeof validateCustomizationTasks>;
    onClearValidationResult: ReturnVoid<typeof clearValidationResult>;
    onListCustomizationTaskDefinitions: ReturnVoid<typeof listCustomizationTaskDefinitions>;
    onDismiss: () => void;
    projects: AddDevBoxFormProjectViewModel[];
    pools: ProjectToPoolViewModelMap;
    images: ImageViewModelMap;
    regions: SerializableMap<RegionViewModel>;
    schedules: ScheduleMap;
    locale: string;
    didSomeProjectFailToLoadCreateResources: boolean;
    userId: string;
    validateCustomizationsResult: CustomizationTaskListValidationResult | undefined;
    projectsAuthorizedForDevBoxCustomize: SerializableMap<ProjectResource | ProjectFromDiscoveryService>;
    customizationTaskDefinitions: SerializableMap<CustomizationTaskDefinition[]>;
    statusForListCustomizationTaskDefinitions: Status;
}

const messages = defineMessages({
    addDevBoxPanelCloseButtonAriaLabel: {
        id: 'AddDevBoxPanel_CloseButton_AriaLabel',
        defaultMessage: 'Add dev box control panel close',
        description: 'Aria label for the help menu panel close button label',
    },
    addDevBoxPanelNameTextFieldAlreadyExistsError: {
        id: 'AddDevBoxPanel_NameTextField_AlreadyExistsError',
        defaultMessage: 'A dev box with this name already exists. Please use a unique name.',
        description: 'Error text indicating that a dev box already exists with this name in the selected pool',
    },
    addDevBoxPanelNameTextFieldInvalidFormatError: {
        id: 'AddDevBoxPanel_NameTextField_InvalidFormatError',
        // Note: ensure this message is kept up-to-date with the validation function.
        defaultMessage:
            'Please enter a name that contains only alphanumeric characters and hyphens, and does not start with a hypen.',
        description: 'Error text indicating that the dev box name is not in a valid format',
    },
    addDevBoxPanelNameTextFieldTooLongError: {
        id: 'AddDevBoxPanel_NameTextField_TooLongError',
        // Note: ensure this message is kept up-to-date with the validation function.
        defaultMessage: 'Please enter a name with less than sixty-three characters.',
        description: 'Error text indicating that the dev box name is too long',
    },
    addDevBoxPanelNameTextFieldTooShortError: {
        id: 'AddDevBoxPanel_NameTextField_TooShortError',
        // Note: ensure this message is kept up-to-date with the validation function.
        defaultMessage: 'Please enter a name with more than three characters.',
        description: 'Error text indicating that the dev box name is too short',
    },
    addDevBoxPanelPoolDropdownRequiredError: {
        id: 'AddDevBoxPanel_PoolDropdown_RequiredError',
        // TODO TASK 2267585: get desired error text from Brandon
        defaultMessage: 'Required',
        description:
            'Error text indicating that the pool dropdown in the add dev box panel has not yet been filled out',
    },
    addDevBoxPanelImageDropdownRequiredError: {
        id: 'AddDevBoxPanel_ImageDropdown_RequiredError',
        // TODO TASK 2267585: get desired error text from Brandon
        defaultMessage: 'Required',
        description:
            'Error text indicating that the image dropdown in the add dev box panel has not yet been filled out',
    },
    addDevBoxPanelRegionDropdownRequiredError: {
        id: 'AddDevBoxPanel_RegionDropdown_RequiredError',
        // TODO TASK 2267585: get desired error text from Brandon
        defaultMessage: 'Required',
        description:
            'Error text indicating that the region dropdown in the add dev box panel has not yet been filled out',
    },
    addDevBoxPanelProjectDropdownRequiredError: {
        id: 'AddDevBoxPanel_ProjectDropdown_RequiredError',
        // TODO TASK 2267585: get desired error text from Brandon
        defaultMessage: 'Required',
        description:
            'Error text indicating that the project dropdown in the add dev box panel has not yet been filled out',
    },
    addDevBoxPanelProjectDropdownLimitReachedError: {
        id: 'AddDevBoxPanel_ProjectDropdown_LimitReachedError',
        defaultMessage:
            'Your project administrator has set a limit of {limit} dev boxes per user in {projectName}. Please delete a dev box in this project, or contact your administrator to increase your limit.',
        description:
            'Text informing users that the limit for dev boxes has been reached in the project. Do not localize {limit}. Do not localize {projectName}.',
    },
    addDevBoxPanelCustomizationsSectionError: {
        id: 'AddDevBoxPanel_CustomizationsSection_Error',
        defaultMessage: 'Customizations error',
        description: 'Error text indicating that there is an error for customizations',
    },
    customizationsTitleAriaLabel: {
        id: 'AddDevBoxPanel_CustomizationsTitle_AriaLabel',
        defaultMessage: 'Customizations (preview)',
        description: 'Aria label for customizations title with preview tag.',
    },
    addDevBoxPanelFileCustomizationsRequiredError: {
        id: 'AddDevBoxPanel_FileCustomizations_RequiredError',
        defaultMessage: 'Required',
        description:
            'Error text indicating that file customizations in the add dev box panel has not yet been filled out',
    },
    addDevBoxPanelRepoCustomizationsRequiredError: {
        id: 'AddDevBoxPanel_RepoCustomizations_RequiredError',
        defaultMessage: 'Required',
        description:
            'Error text indicating that repo customizations in the add dev box panel has not yet been filled out',
    },
});

/**
 * Styles
 */

const useTagTextStyles = legacyMakeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.tagBackgroundColor),
        display: 'inline-flex',
        padding: '4px 6px',
        alignItems: 'flex-start',
        borderRadius: '2px',
        marginLeft: '5px',
        lineHeight: 'initial',
        marginBottom: 'auto',
    },
}));

const useHeaderStyles = makeStyles({
    root: {
        flexGrow: 1,
        paddingLeft: '24px',
        alignSelf: 'flex-start',
        gap: '12px',
    },
});

const useHeaderTextStyles = makeStyles({
    root: {
        fontSize: FontSizes.xLarge,
        fontWeight: FontWeights.semibold,
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        hyphens: 'auto',
        textAlign: 'left',
        marginBottom: '28px',
        alignSelf: 'flex-start',
    },
});

/**
 * End Styles
 */

// Note: NOT putting this in selectors so that we can keep the localized messages contained in this module.
const getErrorMessageDescriptorForDevBoxNameFieldErrorType = (
    devBoxNameFieldErrorType: DevBoxNameFieldErrorType
): MessageDescriptor | undefined => {
    switch (devBoxNameFieldErrorType) {
        case DevBoxNameFieldErrorType.AlreadyExists:
            return messages.addDevBoxPanelNameTextFieldAlreadyExistsError;
        case DevBoxNameFieldErrorType.InvalidFormat:
            return messages.addDevBoxPanelNameTextFieldInvalidFormatError;
        case DevBoxNameFieldErrorType.TooLong:
            return messages.addDevBoxPanelNameTextFieldTooLongError;
        case DevBoxNameFieldErrorType.TooShort:
            return messages.addDevBoxPanelNameTextFieldTooShortError;
        default:
            return undefined;
    }
};

const AddDevBoxPanelComponent: React.FC<AddDevBoxPanelProps> = React.memo((props: AddDevBoxPanelProps) => {
    const {
        devBoxIdentifiers,
        onDismiss,
        onAddDevBoxSubmitted,
        onValidateCustomizationTasks,
        onClearValidationResult,
        onListCustomizationTaskDefinitions,
        customizationTaskDefinitions,
        statusForListCustomizationTaskDefinitions,
        projects,
        pools,
        regions,
        images,
        schedules,
        isOpen,
        locale,
        didSomeProjectFailToLoadCreateResources,
        statusForAddDevBox,
        statusForValidateCustomizationTasks,
        userId,
        validateCustomizationsResult,
        projectsAuthorizedForDevBoxCustomize,
    } = props;
    const { failure: failureForAddDevBox } = statusForAddDevBox ?? {};
    const { validationResult, errors } = validateCustomizationsResult ?? {};

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const headerStyles = useHeaderStyles();
    const headerTextStyles = useHeaderTextStyles();
    const tagTextStyles = useTagTextStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const stackStyles = useStackStyles();

    const [customizationPanelState, setCustomizationPanelState] = React.useState(CustomizationPanels.None);

    const [mostRecentlySubmittedValues, setMostRecentlySubmittedValues] = React.useState<AddDevBoxFormData | undefined>(
        undefined
    );

    const isRoundTripTimeEnabled = isFeatureFlagEnabled(FeatureFlagName.RoundTripTime);

    const isSelectedProjectLimitReached = React.useCallback((project: AddDevBoxFormProjectViewModel | undefined) => {
        return project?.maxDevBoxesPerUser !== undefined && project?.usedDevBoxes >= project?.maxDevBoxesPerUser;
    }, []);

    const initialValues: AddDevBoxFormData = React.useMemo(() => createInitialValues(), []);

    const hasCustomizationsTaskError = React.useMemo(
        () =>
            isStatusTerminal(statusForValidateCustomizationTasks) &&
            validationResult === CustomizationTaskListValidationStatus.Failed,
        [statusForValidateCustomizationTasks, validationResult]
    );

    const isValidatingCustomizations = React.useMemo(
        () => isStatusInProgress(statusForValidateCustomizationTasks),
        [statusForValidateCustomizationTasks]
    );

    const isLoading = React.useMemo(
        () => isStatusInProgress(statusForListCustomizationTaskDefinitions),
        [statusForListCustomizationTaskDefinitions]
    );

    const onFormSubmit = React.useCallback(
        (data: AddDevBoxFormData) => {
            // HACK: react-final-form's dirty/modified doesn't behave correctly, so need to track changes ourselves.
            setMostRecentlySubmittedValues(data);

            const {
                devBoxName,
                selectedPool,
                selectedProject,
                fileCustomizations,
                repoCustomizations,
                selectedIntrinsicTasks,
            } = data;

            const isIntrinsicTasksForCustomizationEnabled = isFeatureFlagEnabled(
                FeatureFlagName.EnableIntrinsicTasksForCustomization
            );

            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            // Justification: Selected pool and project will not be undefined since it is validated via record validation.
            const { id: projectId } = selectedProject!;
            const { name: poolName } = selectedPool!;
            /* eslint-disable @typescript-eslint/no-non-null-assertion */

            const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(projectId);
            const id = createDevBoxDataPlaneUri({ devBoxName, devCenter, projectName, user: userId });

            const customizationGroupId = createCustomizationGroupDataPlaneUri({
                devBoxName,
                devCenter,
                projectName,
                user: userId,
                customizationGroupName: CustomizationGroupName,
            });

            const repoCustomizationsTaskList = repoCustomizations?.taskList ?? [];
            const fileCustomizationsTaskList = fileCustomizations?.taskList ?? [];

            const combinedtaskList = [...repoCustomizationsTaskList, ...fileCustomizationsTaskList];

            // Note: If the task list is empty, rather than including an empty array in the request, we don't include the task list at all.
            const taskList = combinedtaskList.length > 0 ? combinedtaskList : undefined;

            onAddDevBoxSubmitted({
                customizationGroupId,
                id,
                name: devBoxName,
                poolName,
                taskList,
                ...(isIntrinsicTasksForCustomizationEnabled ? { intrinsicTasks: selectedIntrinsicTasks } : {}),
            });
        },
        [onAddDevBoxSubmitted, userId]
    );

    const isDevBoxNameValid = React.useCallback(
        (value: string, project: AddDevBoxFormProjectViewModel | undefined): string | undefined => {
            const devBoxNameErrorType = getDevBoxNameFieldErrorType(value, project?.id, userId, devBoxIdentifiers);
            const errorMessageDescriptor = getErrorMessageDescriptorForDevBoxNameFieldErrorType(devBoxNameErrorType);
            return errorMessageDescriptor !== undefined ? formatMessage(errorMessageDescriptor) : undefined;
        },
        [
            devBoxIdentifiers,
            getDevBoxNameFieldErrorType,
            getErrorMessageDescriptorForDevBoxNameFieldErrorType,
            formatMessage,
            userId,
        ]
    );

    const isSelectedProjectValid = React.useCallback(
        (value: AddDevBoxFormProjectViewModel | undefined): string | undefined => {
            if (isSelectedProjectLimitReached(value)) {
                const limitReachedMessageValues = {
                    limit: value?.maxDevBoxesPerUser,
                    projectName: value?.name,
                };

                return formatMessage(
                    messages.addDevBoxPanelProjectDropdownLimitReachedError,
                    limitReachedMessageValues
                );
            }

            return hasNoValue(value) ? formatMessage(messages.addDevBoxPanelProjectDropdownRequiredError) : undefined;
        },
        [formatMessage]
    );

    const isSelectedPoolValid = React.useCallback(
        (value: PoolViewModel | undefined): string | undefined => {
            return hasNoValue(value) ? formatMessage(messages.addDevBoxPanelPoolDropdownRequiredError) : undefined;
        },
        [formatMessage]
    );

    const isSelectedImageValid = React.useCallback(
        (value: ImageViewModel | undefined): string | undefined => {
            return hasNoValue(value) ? formatMessage(messages.addDevBoxPanelImageDropdownRequiredError) : undefined;
        },
        [formatMessage]
    );

    const isSelectedRegionValid = React.useCallback(
        (value: RegionViewModel | undefined): string | undefined => {
            return hasNoValue(value) ? formatMessage(messages.addDevBoxPanelRegionDropdownRequiredError) : undefined;
        },
        [formatMessage]
    );

    const isUploadCustomizationsValid = React.useCallback(
        (value: boolean, fileCustomizations: CustomizationData | undefined): string | undefined => {
            if (value) {
                return hasNoValue(fileCustomizations?.files) || fileCustomizations?.files?.length === 0
                    ? formatMessage(messages.addDevBoxPanelFileCustomizationsRequiredError)
                    : undefined;
            }

            return undefined;
        },
        [formatMessage]
    );

    const isRepoCustomizationsValid = React.useCallback(
        (value: boolean, repoCustomizations: CustomizationData | undefined): string | undefined => {
            if (value) {
                return hasNoValue(repoCustomizations?.repoFiles) || repoCustomizations?.repoFiles?.length === 0
                    ? formatMessage(messages.addDevBoxPanelRepoCustomizationsRequiredError)
                    : undefined;
            }

            return undefined;
        },
        [formatMessage]
    );

    const formValidate = React.useCallback(
        (values: AddDevBoxFormData) => {
            const {
                devBoxName,
                selectedPool,
                selectedProject,
                selectedImage,
                selectedRegion,
                fileCustomizations,
                repoCustomizations,
                uploadFileEnabled,
                fileFromRepoEnabled,
            } = values;

            return {
                devBoxName: isDevBoxNameValid(devBoxName, selectedProject),
                selectedProject: isSelectedProjectValid(selectedProject),
                selectedPool: isRoundTripTimeEnabled ? undefined : isSelectedPoolValid(selectedPool),
                selectedImage: isRoundTripTimeEnabled ? isSelectedImageValid(selectedImage) : undefined,
                selectedRegion: isRoundTripTimeEnabled ? isSelectedRegionValid(selectedRegion) : undefined,
                uploadFileEnabled: isUploadCustomizationsValid(uploadFileEnabled, fileCustomizations),
                fileFromRepoEnabled: isRepoCustomizationsValid(fileFromRepoEnabled, repoCustomizations),
            };
        },
        [
            isDevBoxNameValid,
            isSelectedProjectValid,
            isSelectedPoolValid,
            isSelectedImageValid,
            isUploadCustomizationsValid,
            isRepoCustomizationsValid,
            isSelectedRegionValid,
            isRoundTripTimeEnabled,
        ]
    );

    const isErrorOrFailedAddDevBoxState = isStatusUnsuccessful(statusForAddDevBox);

    return (
        <Form<AddDevBoxFormData> initialValues={initialValues} onSubmit={onFormSubmit} validate={formValidate}>
            {(formProps) => {
                const { form, valid, submitting, values } = formProps;
                const { reset, restart, submit, change } = form;
                const {
                    devBoxName,
                    selectedPool,
                    selectedProject,
                    selectedImage,
                    selectedRegion,
                    fileCustomizations,
                    repoCustomizations,
                    selectedIntrinsicTasks,
                    applyCustomizationsEnabled,
                    fileFromRepoEnabled,
                    uploadFileEnabled,
                } = values;

                const isSubmitting = submitting || isStatusInProgress(statusForAddDevBox);

                // detect when we should close ourselves
                const previousStatusForAddDevBox = usePrevious(statusForAddDevBox);
                React.useEffect(() => {
                    if (isStatusInProgress(previousStatusForAddDevBox) && isStatusSuccessful(statusForAddDevBox)) {
                        onDismiss();

                        // This will reset the form and reset the field state per field.
                        restart(initialValues);
                    }
                }, [previousStatusForAddDevBox, statusForAddDevBox, initialValues, onDismiss, restart]);

                const uploadFileEnabledOnChange = React.useCallback(
                    (value: boolean) => {
                        change(AddDevBoxFormField.UploadFileEnabled, value);
                        onClearValidationResult();

                        if (value === false) {
                            change(AddDevBoxFormField.FileCustomizations, undefined);
                        }
                    },
                    [change, onClearValidationResult]
                );

                const fileFromRepoEnabledOnChange = React.useCallback(
                    (value: boolean) => {
                        change(AddDevBoxFormField.FileFromRepoEnabled, value);
                        onClearValidationResult();

                        if (value === false) {
                            change(AddDevBoxFormField.RepoCustomizations, undefined);
                        }
                    },
                    [change, onClearValidationResult]
                );

                const onCancelClicked = React.useCallback(() => {
                    onDismiss();
                    reset();
                }, [onDismiss, reset]);

                const onCustomizeClicked = React.useCallback(
                    () => setCustomizationPanelState(CustomizationPanels.CustomizationFiles),
                    []
                );

                const onContinueClicked = React.useCallback(() => {
                    setCustomizationPanelState(CustomizationPanels.Summary);
                }, []);

                const onPreviousClicked = React.useCallback(() => {
                    if (customizationPanelState === CustomizationPanels.CustomizationFiles) {
                        uploadFileEnabledOnChange(false);
                        fileFromRepoEnabledOnChange(false);
                        setCustomizationPanelState(CustomizationPanels.None);
                    }

                    if (customizationPanelState === CustomizationPanels.Summary) {
                        setCustomizationPanelState(CustomizationPanels.CustomizationFiles);
                    }
                }, [uploadFileEnabledOnChange, fileFromRepoEnabledOnChange, customizationPanelState]);

                const userCanCustomizeSelectedProject = React.useMemo(() => {
                    if (selectedProject) {
                        return has(projectsAuthorizedForDevBoxCustomize, selectedProject.id);
                    }

                    return false;
                }, [selectedProject, projectsAuthorizedForDevBoxCustomize]);

                // HACK: react-final-form's dirty/modified doesn't behave correctly, so need to track changes ourselves.
                const hasBeenModifiedSinceLastSubmit = React.useMemo(() => {
                    if (!mostRecentlySubmittedValues) {
                        return true;
                    }

                    return (
                        mostRecentlySubmittedValues.applyCustomizationsEnabled !== applyCustomizationsEnabled ||
                        mostRecentlySubmittedValues.devBoxName !== devBoxName ||
                        mostRecentlySubmittedValues.fileCustomizations !== fileCustomizations ||
                        mostRecentlySubmittedValues.fileFromRepoEnabled !== fileFromRepoEnabled ||
                        mostRecentlySubmittedValues.repoCustomizations !== repoCustomizations ||
                        mostRecentlySubmittedValues.selectedIntrinsicTasks !== selectedIntrinsicTasks ||
                        mostRecentlySubmittedValues.selectedPool !== selectedPool ||
                        mostRecentlySubmittedValues.selectedProject !== selectedProject ||
                        mostRecentlySubmittedValues.uploadFileEnabled !== uploadFileEnabled ||
                        mostRecentlySubmittedValues.selectedImage !== selectedImage ||
                        mostRecentlySubmittedValues.selectedRegion !== selectedRegion
                    );
                }, [
                    applyCustomizationsEnabled,
                    devBoxName,
                    fileCustomizations,
                    fileFromRepoEnabled,
                    mostRecentlySubmittedValues,
                    repoCustomizations,
                    selectedIntrinsicTasks,
                    selectedPool,
                    selectedProject,
                    selectedImage,
                    uploadFileEnabled,
                    selectedRegion,
                ]);

                const hasCustomizationTaskDefinitions = React.useMemo(() => {
                    const taskDefinitions = get(customizationTaskDefinitions, selectedProject?.id);

                    return taskDefinitions ? taskDefinitions.length > 0 : false;
                }, [customizationTaskDefinitions, selectedProject]);

                const hasRepoTaskList = fileFromRepoEnabled && !!repoCustomizations?.taskList;
                const hasFileTaskList = uploadFileEnabled && !!fileCustomizations?.taskList;

                const shouldValidate = React.useMemo(
                    () =>
                        (hasRepoTaskList || hasFileTaskList) &&
                        (hasCustomizationsTaskError ||
                            isValidatingCustomizations ||
                            isStatusNotStarted(statusForValidateCustomizationTasks)),
                    [
                        fileFromRepoEnabled,
                        uploadFileEnabled,
                        hasCustomizationsTaskError,
                        isValidatingCustomizations,
                        statusForValidateCustomizationTasks,
                        fileCustomizations,
                        repoCustomizations,
                    ]
                );

                const onValidateClicked = React.useCallback(() => {
                    onClearValidationResult();

                    if (selectedProject && (!!fileCustomizations || !!repoCustomizations)) {
                        onValidateCustomizationTasks({
                            tasks: [
                                ...((fileCustomizations?.taskList as PutCustomizationTask[]) ?? []),
                                ...((repoCustomizations?.taskList as PutCustomizationTask[]) ?? []),
                            ],
                            projectId: selectedProject.id,
                        });
                    }
                }, [fileCustomizations, repoCustomizations, selectedProject, onClearValidationResult]);

                const scheduleForSelectedPool: ScheduleViewModel | undefined = React.useMemo(() => {
                    if (selectedPool) {
                        return getScheduleViewModel(schedules, selectedPool.id, locale) ?? undefined;
                    }

                    return undefined;
                }, [selectedPool, locale, schedules]);

                const onRenderFooterContent = React.useCallback(() => {
                    return (
                        <AddDevBoxPanelFooter
                            failure={failureForAddDevBox}
                            isErrorOrFailedState={isErrorOrFailedAddDevBoxState}
                            isSubmitting={isSubmitting}
                            isValidatingCustomizations={isValidatingCustomizations}
                            applyCustomizationsEnabled={applyCustomizationsEnabled}
                            customizationPanelState={customizationPanelState}
                            userCanCustomizeSelectedProject={userCanCustomizeSelectedProject}
                            isValid={valid}
                            onCancelClicked={onCancelClicked}
                            onContinueClicked={onContinueClicked}
                            onCustomizeClicked={onCustomizeClicked}
                            onPreviousClicked={onPreviousClicked}
                            onValidateClicked={onValidateClicked}
                            onSubmitClicked={submit}
                            shouldValidate={shouldValidate}
                            schedule={scheduleForSelectedPool}
                            hasCustomizationTaskDefinitions={hasCustomizationTaskDefinitions}
                            hasBeenModifiedSinceLastSubmit={hasBeenModifiedSinceLastSubmit}
                        />
                    );
                }, [
                    failureForAddDevBox,
                    isErrorOrFailedAddDevBoxState,
                    isSubmitting,
                    customizationPanelState,
                    isValidatingCustomizations,
                    applyCustomizationsEnabled,
                    userCanCustomizeSelectedProject,
                    valid,
                    scheduleForSelectedPool,
                    shouldValidate,
                    hasCustomizationTaskDefinitions,
                    hasBeenModifiedSinceLastSubmit,
                    onCancelClicked,
                    onContinueClicked,
                    onCustomizeClicked,
                    onPreviousClicked,
                    onValidateClicked,
                    submit,
                ]);

                const onRenderHeader = React.useCallback(() => {
                    if (customizationPanelState === CustomizationPanels.CustomizationFiles) {
                        return (
                            <div className={mergeClasses(horizontalStackStyles.root, headerStyles.root)}>
                                <div className={mergeClasses(horizontalStackStyles.item, headerTextStyles.root)}>
                                    <FormattedMessage
                                        id="AddDevBoxPanel_CustomizationsTitle_Text"
                                        defaultMessage="Customize your dev box"
                                        description="Text for add dev box panel customizations title"
                                    />
                                </div>
                                <div className={horizontalStackStyles.item}>
                                    <Text
                                        aria-label={formatMessage(messages.customizationsTitleAriaLabel)}
                                        styles={tagTextStyles}
                                    >
                                        <FormattedMessage
                                            id="AddDevBoxPanel_CustomizationsTitle_PreviewTag"
                                            defaultMessage="preview"
                                            description="A tag displayed to the right of the 'Customize your dev box' title of the create dev box form panel indicating that customizations is a preview feature."
                                        />
                                    </Text>
                                </div>
                            </div>
                        );
                    }

                    if (customizationPanelState === CustomizationPanels.Summary) {
                        return (
                            <div className={mergeClasses(stackStyles.root, headerStyles.root)}>
                                <div className={mergeClasses(stackStyles.item, headerTextStyles.root)}>
                                    <FormattedMessage
                                        id="AddDevBoxPanel_SummaryTitle_Text"
                                        defaultMessage="Dev box creation summary"
                                        description="Text for dev box creation summary title"
                                    />
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div className={mergeClasses(stackStyles.root, headerStyles.root)}>
                            <div className={mergeClasses(stackStyles.item, headerTextStyles.root)}>
                                <FormattedMessage
                                    id="AddDevBoxPanel_Title_Text"
                                    defaultMessage="Add a dev box"
                                    description="Text for add dev box panel title"
                                />
                            </div>
                        </div>
                    );
                }, [
                    customizationPanelState,
                    headerStyles,
                    tagTextStyles,
                    headerTextStyles,
                    horizontalStackStyles,
                    stackStyles,
                    formatMessage,
                ]);

                const projectOnChange = React.useCallback(
                    (value: AddDevBoxFormProjectViewModel | undefined) => {
                        if (value && get(customizationTaskDefinitions, value.id) === undefined) {
                            onListCustomizationTaskDefinitions({ id: value.id });
                        }

                        onClearValidationResult();
                        change(AddDevBoxFormField.SelectedProject, value);
                    },
                    [change, onClearValidationResult, onListCustomizationTaskDefinitions, customizationTaskDefinitions]
                );

                const fileCustomizationsOnChange = React.useCallback(
                    (value: CustomizationData | undefined) => {
                        onClearValidationResult();
                        change(AddDevBoxFormField.FileCustomizations, value);
                    },
                    [change, onClearValidationResult]
                );

                const repoCustomizationsOnChange = React.useCallback(
                    (value: CustomizationData | undefined) => {
                        onClearValidationResult();
                        change(AddDevBoxFormField.RepoCustomizations, value);
                    },
                    [change, onClearValidationResult]
                );

                const intrinsicTasksOnChange = React.useCallback(
                    (value: IntrinsicTask[] | undefined) => {
                        onClearValidationResult();
                        change(AddDevBoxFormField.SelectedIntrinsicTasks, value);
                    },
                    [change, onClearValidationResult]
                );

                return (
                    <FormPanel
                        isOpen={isOpen}
                        isLightDismiss={!isSubmitting}
                        onDismiss={isSubmitting ? undefined : onCancelClicked}
                        closeButtonAriaLabel={formatMessage(messages.addDevBoxPanelCloseButtonAriaLabel)}
                        onRenderFooterContent={onRenderFooterContent}
                        onRenderHeader={onRenderHeader}
                    >
                        {customizationPanelState === CustomizationPanels.CustomizationFiles && (
                            <CustomizationFileFormFieldGroup
                                isSubmitting={isSubmitting}
                                statusForValidateCustomizationTasks={statusForValidateCustomizationTasks}
                                validationResult={validationResult}
                                errors={errors}
                                fileCustomizationsOnChange={fileCustomizationsOnChange}
                                repoCustomizationsOnChange={repoCustomizationsOnChange}
                                uploadFileEnabledOnChange={uploadFileEnabledOnChange}
                                fileFromRepoEnabledOnChange={fileFromRepoEnabledOnChange}
                                fileCustomizations={fileCustomizations}
                                fileFromRepoEnabled={fileFromRepoEnabled}
                                uploadFileEnabled={uploadFileEnabled}
                                repoCustomizations={repoCustomizations}
                            />
                        )}
                        {customizationPanelState === CustomizationPanels.Summary && (
                            <AddDevBoxSummary
                                isSubmitting={isSubmitting}
                                name={devBoxName}
                                project={selectedProject}
                                pool={selectedPool}
                                fileCustomizations={fileCustomizations}
                                repoCustomizations={repoCustomizations}
                            />
                        )}
                        {customizationPanelState === CustomizationPanels.None && (
                            <AddDevBoxFormFieldGroup
                                isSubmitting={isSubmitting}
                                projects={projects}
                                pools={pools}
                                images={images}
                                regions={regions}
                                devBoxName={devBoxName}
                                selectedProject={selectedProject}
                                selectedPool={selectedPool}
                                selectedImage={selectedImage}
                                selectedRegion={selectedRegion}
                                fileCustomizations={fileCustomizations}
                                selectedIntrinsicTasks={selectedIntrinsicTasks}
                                didSomeProjectFailToLoadCreateResources={didSomeProjectFailToLoadCreateResources}
                                isSelectedProjectLimitReached={isSelectedProjectLimitReached}
                                projectOnChange={projectOnChange}
                                customizationsOnChange={fileCustomizationsOnChange}
                                intrinsicTasksOnChange={intrinsicTasksOnChange}
                                projectsAuthorizedForDevBoxCustomize={projectsAuthorizedForDevBoxCustomize}
                                statusForValidateCustomizationTasks={statusForValidateCustomizationTasks}
                                validationResult={validationResult}
                                errors={errors}
                                isLoading={isLoading}
                                hasCustomizationTaskDefinitions={hasCustomizationTaskDefinitions}
                            />
                        )}
                    </FormPanel>
                );
            }}
        </Form>
    );
});

const AddDevBoxPanelContainer: React.FC = () => {
    // Application state hooks
    const devBoxIdentifiers = useSelector(getDevBoxIds);
    const projects = useSelector(getProjectViewModels);
    const projectsFromDiscoveryService = useSelector(getProjectFromDiscoveryServiceViewModels);
    const pools = useSelector(getPoolViewModels);
    const statusForAddDevBox = useSelector(getStatusForAddDevBox);
    const schedules = useSelector(getSchedules);
    const locale = useSelector(getLocale);
    const didSomeProjectFailToLoadCreateResources = useSelector(getDidSomeProjectFailToLoadDevBoxCreateResources);
    const userId = useSelector(getObjectId);
    const validateCustomizationsResult = useSelector(getValidateCustomizationTasksResult);
    const statusForValidateCustomizationTasks = useSelector(getStatusForValidateCustomizationTasks);
    const projectsAuthorizedForDevBoxCustomize = useSelector(getProjectsAuthorizedForDevBoxCustomizeByDataPlaneId);
    const projectsFromDiscoveryServiceAuthorizedForDevBoxCustomize = useSelector(
        getProjectsFromDiscoveryServiceAuthorizedForDevBoxCustomize
    );
    const customizationTaskDefinitions = useSelector(getCustomizationTaskDefinitions);
    const statusForListCustomizationTaskDefinitions = useSelector(getStatusForListCustomizationTaskDefinitions);
    const images = useSelector(getImageViewModels);
    const regions = useSelector(getRegionViewModels);

    const projectsToUse = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
        ? projectsFromDiscoveryService
        : projects;

    const projectsFromDiscoveryServiceAuthorizedForDevBoxCustomizeToUse = isFeatureFlagEnabled(
        FeatureFlagName.EnableDiscoveryService
    )
        ? projectsFromDiscoveryServiceAuthorizedForDevBoxCustomize
        : projectsAuthorizedForDevBoxCustomize;

    // Action hooks
    const onAddDevBoxSubmitted = useActionCreator(addDevBox);
    const onClearAddDevBoxFailure = useActionCreator(clearAddDevBoxFailure);
    const onClearValidationResult = useActionCreator(clearValidationResult);
    const onValidateCustomizationTasks = useActionCreator(validateCustomizationTasks);
    const onListCustomizationTaskDefinitions = useActionCreator(listCustomizationTaskDefinitions);

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useAddDevBoxPanelContext();

    const onDismiss = React.useCallback(() => {
        closePanel();
        onClearAddDevBoxFailure();
        onClearValidationResult();
    }, [closePanel, onClearAddDevBoxFailure, onClearValidationResult]);

    return (
        <AddDevBoxPanelComponent
            onDismiss={onDismiss}
            isOpen={isOpen}
            onAddDevBoxSubmitted={onAddDevBoxSubmitted}
            onValidateCustomizationTasks={onValidateCustomizationTasks}
            onClearValidationResult={onClearValidationResult}
            onListCustomizationTaskDefinitions={onListCustomizationTaskDefinitions}
            statusForAddDevBox={statusForAddDevBox}
            projects={projectsToUse}
            pools={pools}
            images={images}
            regions={regions}
            schedules={schedules}
            devBoxIdentifiers={devBoxIdentifiers}
            locale={locale}
            didSomeProjectFailToLoadCreateResources={didSomeProjectFailToLoadCreateResources}
            userId={userId}
            statusForValidateCustomizationTasks={statusForValidateCustomizationTasks}
            validateCustomizationsResult={validateCustomizationsResult}
            projectsAuthorizedForDevBoxCustomize={projectsFromDiscoveryServiceAuthorizedForDevBoxCustomizeToUse}
            customizationTaskDefinitions={customizationTaskDefinitions}
            statusForListCustomizationTaskDefinitions={statusForListCustomizationTaskDefinitions}
        />
    );
};

export const AddDevBoxPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useAddDevBoxPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <AddDevBoxPanelContainer />;
};

export default AddDevBoxPanelContextWrapper;

import { Depths, FontSizes, makeStyles as legacyMakeStyles } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../utilities/styles';

type ConnectViaAppDownloadCardProps = {
    contentFooter?: React.ReactNode;
    paddingBottom?: number;
    subtitle: React.ReactNode;
};

/**
 * Styles
 */

const rootStylesFactory = (paddingBottom?: number) =>
    legacyMakeStyles((theme) => ({
        root: {
            backgroundColor: getSemanticColor(theme, 'openWindowsDesktopClientOptionCardBackground'),
            borderRadius: '4px',
            boxShadow: Depths.depth16,
            gap: '24px',
            padding: paddingBottom !== undefined ? `32px 32px ${paddingBottom}px 32px` : '32px',
        },
    }));

const useSubtitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        lineHeight: '16px',
        marginBottom: '10px',
    },
});

const useTextContainerStyles = makeStyles({
    root: {
        // setting a min width value for this element allows it to shrink
        minWidth: 0,
        flexGrow: 1,
    },
});

const useTextStyles = makeStyles({
    root: {
        textAlign: 'left',
        width: '100%',
        gap: '2px',
    },
});

const useContentStyles = makeStyles({
    root: {
        gap: '20px',
        alignItems: 'start',
    },
});

/**
 * END Styles
 */

export const ConnectViaAppDownloadCard: React.FC<ConnectViaAppDownloadCardProps> = (
    props: ConnectViaAppDownloadCardProps
) => {
    const { contentFooter, paddingBottom, subtitle } = props;

    // Style hooks
    const useRootStyles = useDynamicMakeStyles(rootStylesFactory, paddingBottom);
    const rootStyles = useRootStyles();
    const subtitleStyles = useSubtitleStyles();
    const textContainerStyles = useTextContainerStyles();
    const textStyles = useTextStyles();
    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const contentStyles = useContentStyles();

    return (
        <div className={mergeClasses(stackStyles.root, rootStyles.root)}>
            <div className={stackStyles.item}>
                <div className={mergeClasses(stackStyles.root, contentStyles.root)}>
                    <div className={mergeClasses(horizontalStackStyles.item, textContainerStyles.root)}>
                        <div className={mergeClasses(stackStyles.root, textStyles.root)}>
                            <div className={mergeClasses(stackStyles.item, subtitleStyles.root)}>{subtitle}</div>
                            {contentFooter && <div className={stackStyles.item}>{contentFooter}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectViaAppDownloadCard;

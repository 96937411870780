import { Checkbox, ICheckboxProps, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { Field } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import { useStackStyles } from '../../themes/styles/flexbox-styles';
import { DevMenuFormFieldData, DevMenuFormFieldName } from './models';

const messages = defineMessages({
    enableBetaDataPlaneCheckboxAriaLabel: {
        id: 'DevMenuFormFieldGroup_EnableBetaDataPlaneCheckbox_AriaLabel',
        defaultMessage: 'Enable beta Data Plane SDK',
        description: 'Aria label for "Enable beta Data Plane SDK" checkbox',
    },
    enableBetaDataPlaneCheckboxLabel: {
        id: 'DevMenuFormFieldGroup_EnableBetaDataPlaneCheckbox_Label',
        defaultMessage: 'Enable beta Data Plane SDK',
        description: 'Label for "Enable beta Data Plane SDK" checkbox',
    },
    underDevelopmentCheckboxAriaLabel: {
        id: 'DevMenuFormFieldGroup_UnderDevelopmentCheckbox_AriaLabel',
        defaultMessage: 'Enable dev code',
        description: 'Aria label for "Enable dev code" checkbox',
    },
    underDevelopmentCheckboxLabel: {
        id: 'DevMenuFormFieldGroup_UnderDevelopmentCheckbox_Label',
        defaultMessage: 'Enable dev code',
        description: 'Label for "Enable dev code" checkbox',
    },
    useNonPreauthorizedScopesCheckboxAriaLabel: {
        id: 'DevMenuFormFieldGroup_UseNonPreauthorizedScopesCheckbox_AriaLabel',
        defaultMessage: 'Use non pre-authorized scopes',
        description: 'Aria label for "Use non pre-authorized scopes" checkbox',
    },
    useNonPreauthorizedScopesCheckboxLabel: {
        id: 'DevMenuFormFieldGroup_UseNonPreauthorizedScopesCheckbox_Label',
        defaultMessage: 'Use non pre-authorized scopes',
        description: 'Label for "Use non pre-authorized scopes" checkbox',
    },
    logVerboseCheckboxAriaLabel: {
        id: 'DevMenuFormFieldGroup_LogVerboseCheckbox_AriaLabel',
        defaultMessage: 'Enable verbose logging',
        description: 'Aria label for "Enable first click RDP" checkbox',
    },
    logVerboseCheckboxLabel: {
        id: 'DevMenuFormFieldGroup_LogVerboseCheckbox_Label',
        defaultMessage: 'Enable verbose logging',
        description: 'Label for "Enable first click RDP" checkbox',
    },
    enableThemeModeToggleLabel: {
        id: 'DevMenuFormFieldGroup_EnableThemeModeToggle_Label',
        defaultMessage: 'Enable theme toggle button',
        description: 'Label for "Enable theme toggle" checkbox',
    },
    enableSingleDevCenterModeLabel: {
        id: 'DevMenuFormFieldGroup_EnableSingleDevCenterMode_Label',
        defaultMessage: 'Enable single dev center mode',
        description: 'Label for "Enable single dev center mode" checkbox',
    },
    enableDisplayNamesLabel: {
        id: 'DevMenuFormFieldGroup_EnableDisplayNames_Label',
        defaultMessage: 'Enable display names',
        description: 'Label for "Enable display names" checkbox',
    },
    enableRoundTripTimeLabel: {
        id: 'DevMenuFormFieldGroup_EnableRoundTripTime_Label',
        defaultMessage: 'Enable round trip time',
        description: 'Label for "Enable round trip time" checkbox',
    },
    enableOpenUserSettingsLabel: {
        id: 'DevMenuFormFieldGroup_EnableOpenUserSettings_Label',
        defaultMessage: 'Enable open user settings',
        description: 'Label for "Enable open user settings" checkbox',
    },
    enableConnectViaAppLabel: {
        id: 'DevMenuFormFieldGroup_EnableConnectViaAppSettings_Label',
        defaultMessage: 'Enable connect via app setting',
        description: 'Label for "Enable connect via app" checkbox',
    },
    enableWindowsAppSettingLabel: {
        id: 'DevMenuFormFieldGroup_EnableWindowsAppSettings_Label',
        defaultMessage: 'Enable Windows app settings',
        description: 'Label for "Enable windows app settings" checkbox',
    },
    enableDiscoveryServiceLabel: {
        id: 'DevMenuFormFieldGroup_EnableDiscoveryService_Label',
        defaultMessage: 'Enable discovery service',
        description: 'Label for "Enable discovery service" checkbox',
    },
    enableIntrinsicTasksForCustomizationLabel: {
        id: 'DevMenuFormFieldGroup_EnableIntrinsicTasksForCustomization_Label',
        defaultMessage: 'Enable intrinsic tasks for customization',
        description: 'Label for "Enable intrinsic tasks for customization" checkbox',
    },
    enableGuestUserLabel: {
        id: 'DevMenuFormFieldGroup_EnableGuestUser_Label',
        defaultMessage: 'Enable guest user',
        description: 'Label for "Enable guest user" checkbox',
    },
});

/**
 * Styles
 */

const useCheckboxStyles = makeStyles({
    root: {
        marginBottom: 8,
    },
});

/**
 * END Styles
 */

interface DevMenuFormFieldProps extends Pick<ICheckboxProps, 'ariaLabel' | 'label'> {
    name: string;
}

const DevMenuFormField: React.FC<DevMenuFormFieldProps> = (props: DevMenuFormFieldProps) => {
    const { name } = props;

    // Style hooks
    const checkboxStyles = useCheckboxStyles();

    return (
        <Field<DevMenuFormFieldData> name={name}>
            {(fieldProps) => {
                // Callback hooks
                const onChange = React.useCallback(
                    (_event: unknown, checked?: boolean) => {
                        const { value } = fieldProps.input;

                        fieldProps.input.onChange(
                            DevMenuFormFieldData({
                                ...value,
                                enabled: checked ?? false,
                            })
                        );
                    },
                    [fieldProps.input.onChange, fieldProps.input.value]
                );

                return (
                    <Checkbox
                        {...props}
                        onChange={onChange}
                        checked={fieldProps.input.value.enabled}
                        disabled={fieldProps.input.value.frozen}
                        styles={checkboxStyles}
                    />
                );
            }}
        </Field>
    );
};

export const DevMenuFormFieldGroup: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const stackStyles = useStackStyles();

    return (
        <div className={stackStyles.root}>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.enableBetaDataPlane}
                    ariaLabel={formatMessage(messages.enableBetaDataPlaneCheckboxAriaLabel)}
                    label={formatMessage(messages.enableBetaDataPlaneCheckboxLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.underDevelopment}
                    ariaLabel={formatMessage(messages.underDevelopmentCheckboxAriaLabel)}
                    label={formatMessage(messages.underDevelopmentCheckboxLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.useNonPreauthorizedScopes}
                    ariaLabel={formatMessage(messages.useNonPreauthorizedScopesCheckboxAriaLabel)}
                    label={formatMessage(messages.useNonPreauthorizedScopesCheckboxLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.logVerbose}
                    ariaLabel={formatMessage(messages.logVerboseCheckboxAriaLabel)}
                    label={formatMessage(messages.logVerboseCheckboxLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.enableThemeModeToggle}
                    ariaLabel={formatMessage(messages.enableThemeModeToggleLabel)}
                    label={formatMessage(messages.enableThemeModeToggleLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.singleDevCenterMode}
                    ariaLabel={formatMessage(messages.enableSingleDevCenterModeLabel)}
                    label={formatMessage(messages.enableSingleDevCenterModeLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.displayNames}
                    ariaLabel={formatMessage(messages.enableDisplayNamesLabel)}
                    label={formatMessage(messages.enableDisplayNamesLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.roundTripTime}
                    ariaLabel={formatMessage(messages.enableRoundTripTimeLabel)}
                    label={formatMessage(messages.enableRoundTripTimeLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.openUserSettingsMenu}
                    ariaLabel={formatMessage(messages.enableOpenUserSettingsLabel)}
                    label={formatMessage(messages.enableOpenUserSettingsLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.connectViaApp}
                    ariaLabel={formatMessage(messages.enableConnectViaAppLabel)}
                    label={formatMessage(messages.enableConnectViaAppLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.enableWindowsAppSettings}
                    ariaLabel={formatMessage(messages.enableWindowsAppSettingLabel)}
                    label={formatMessage(messages.enableWindowsAppSettingLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.enableDiscoveryService}
                    ariaLabel={formatMessage(messages.enableDiscoveryServiceLabel)}
                    label={formatMessage(messages.enableDiscoveryServiceLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.enableIntrinsicTasksForCustomization}
                    ariaLabel={formatMessage(messages.enableIntrinsicTasksForCustomizationLabel)}
                    label={formatMessage(messages.enableIntrinsicTasksForCustomizationLabel)}
                />
            </div>
            <div className={stackStyles.item}>
                <DevMenuFormField
                    name={DevMenuFormFieldName.enableGuestUser}
                    ariaLabel={formatMessage(messages.enableGuestUserLabel)}
                    label={formatMessage(messages.enableGuestUserLabel)}
                />
            </div>
        </div>
    );
};
